<template>
  <v-container fluid>
    <!-- <template v-if="!newOrderFlag"> -->
    <v-row class="py-1 pb-5">
      <Panel class="ma-auto"></Panel>
    </v-row>
    <AllOrdersActions />
    <v-row>
      <Table :items="ordersFiltered" :loadingFlag="allOrdersTableFlag"></Table>
    </v-row>
    <!-- </template> -->
    <!-- <template v-else>
      <NewOrderActions :newOrderFlag="newOrderFlag" />
      <v-row>
        <v-col cols="12">
          <NewOrder />
        </v-col>
      </v-row>
    </template> -->
  </v-container>
</template>

<script>
import Table from "@/components/OM/Table";
import AllOrdersActions from "@/components/OM/AllOrders/AllOrdersActions.vue";
// import NewOrder from "@/components/OM/AllOrders/NewOrder.vue";
// import NewOrderActions from "@/components/OM/AllOrders/NewOrderActions.vue";
import Panel from "@/components/OM/Panel";

import { HEADER } from "@/helpers/mocks/orders.js";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { orderBy } from "natural-orderby";
import { search } from "@/utils/panelAction.js";
export default {
  components: {
    Table,
    // NewOrder,
    AllOrdersActions,
    // NewOrderActions,
    Panel,
  },
  async created() {
    if (!this.newOrderFlag) {
      this.allOrdersTableFlag = true;
      // this.resetDetailsPage();
      await this.getAllOrderWithDetails();

      this.allOrdersItems = this.allOrders.map((x) => {
        const isTBD = !!this.eoo[x.docNo] ? this.getTBD(this.eoo[x.docNo]) : "TBD";

        return {
          ...x,
          depots: this.docDetailsNosOrder[x.docNo]
            ? this.getUnique(this.docDetailsNosOrder[x.docNo], "Location", x.docNo)
            : [],
          isTBD,
          releases: this.docDetailsNosOrder[x.docNo]
            ? this.getUnique(this.docDetailsNosOrder[x.docNo], "rn2_release_ref", x.docNo)
            : [],
          containers: !!this.eoo[x.docNo]
            ? this.getUnique(this.eoo[x.docNo], "eooContainerNo")
            : [],
        };
      });

      this.openItems = this.getOpenOrders();
      this.completeItems = this.getCompleteOrders();
      this.historyItems = this.getHistoryOrders();
      this.canceledItems = this.getCanceledOrders();
      this.closedItems = this.getClosedItems();
      this.setTabLengths({
        open: this.openItems.length,
        complete: this.completeItems.length,
        history: this.historyItems.length,
      });
      this.allOrdersTableFlag = false;
    }
  },
  computed: {
    ...mapState({
      tabSelected: (state) => state.moduleOrders.tabSelected,
      searchInput: (state) => state.moduleOrders.searchInput,
      newOrderFlag: (state) => state.moduleOrders.newOrderFlag,
      allOrders: (state) => state.moduleOrders.allOrders,
      docDetailsNosOrder: (state) => state.moduleOrders.docDetailsNosOrder,
      historySwitch: (state) => state.moduleOrders.historySwitch,
      eoo: (state) => state.moduleOrders.eoo,
    }),
    ...mapGetters({
      docNo: "moduleOrders/docNo",
    }),
    itemsFiltered() {
      return orderBy(
        this.ordersTab,
        ["vendStepDescr", "coName", "docDate"],
        ["asc", "asc", "desc"]
      );
    },
    ordersFiltered() {
      if (!this.searchInput || !this.searchInput.length) return this.itemsFiltered;

      let normalColumns = search({
        searchInput: this.searchInput,
        header: HEADER,
        arr: this.itemsFiltered,
      });

      let docNoNormalColumns = normalColumns.map((x) => x.docNo);

      const depots = this.itemsFiltered.filter((x) => {
        if (!docNoNormalColumns.includes(x.docNo)) {
          const depotsFiltered = x.depots.filter((depot) => {
            return depot
              .toString()
              .toLowerCase()
              .includes(this.searchInput.toLowerCase());
          });
          return !!depotsFiltered.length;
        } else {
          return false;
        }
      });

      docNoNormalColumns = [...docNoNormalColumns, ...depots.map((x) => x.docNo)];
      const releases = this.itemsFiltered.filter((x) => {
        if (!docNoNormalColumns.includes(x.docNo)) {
          const releasesFiltered = x.releases.filter((release) => {
            return release
              .toString()
              .toLowerCase()
              .includes(this.searchInput.toLowerCase());
          });
          return !!releasesFiltered.length;
        } else {
          return false;
        }
      });
      docNoNormalColumns = [...docNoNormalColumns, ...releases.map((x) => x.docNo)];

      const containers = this.itemsFiltered.filter((x) => {
        if (!docNoNormalColumns.includes(x.docNo)) {
          const containersFiltered = x.containers.filter((container) => {
            return container
              .toString()
              .toLowerCase()
              .includes(this.searchInput.toLowerCase());
          });
          return !!containersFiltered.length;
        } else {
          return false;
        }
      });
      return [...normalColumns, ...depots, ...releases, ...containers];
    },
    ordersTab() {
      switch (this.tabSelected.index) {
        case 0:
          return this.openItems;

        case 1:
          return this.completeItems;

        case 2:
          return this.historySwitch ? this.canceledItems : this.closedItems;
      }
    },
  },
  data() {
    return {
      allOrdersTableFlag: false,
      allOrdersItems: [],
      openItems: [],
      completeItems: [],
      historyItems: [],
      canceledItems: [],
      closedItems: [],
    };
  },

  methods: {
    ...mapActions({
      getAllOrderWithDetails: "moduleOrders/getAllOrderWithDetails",
      getsAllOrders: "moduleOrders/getsAllOrders",
      getAllOrdersDetail: "moduleOrders/getAllOrdersDetail",
      resetDetailsPage: "moduleOrders/moduleDetails/resetDetailsPage",
      setTabLengths: "moduleOrders/setTabLengths",
    }),
    getOpenOrders() {
      return this.allOrdersItems.filter(
        ({ vendStepDescr }) =>
          vendStepDescr.toUpperCase().startsWith("QUOTE") ||
          vendStepDescr.toUpperCase().startsWith("NEW") ||
          vendStepDescr.toUpperCase().startsWith("PAID") ||
          vendStepDescr.toUpperCase().startsWith("PENDING PICK UP")
      );
    },
    getCompleteOrders() {
      return this.allOrdersItems.filter(({ vendStepDescr }) =>
        vendStepDescr.toUpperCase().startsWith("COMPLETED")
      );
    },
    getHistoryOrders() {
      return this.allOrdersItems.filter(
        ({ vendStepDescr }) =>
          vendStepDescr.toUpperCase().startsWith("CANCELED") ||
          vendStepDescr.toUpperCase().startsWith("CLOSED")
      );
    },
    getCanceledOrders() {
      return this.historyItems.filter(({ vendStepDescr }) =>
        vendStepDescr.toUpperCase().startsWith("CANCELED")
      );
    },
    getClosedItems() {
      return this.historyItems.filter(({ vendStepDescr }) =>
        vendStepDescr.toUpperCase().startsWith("CLOSED")
      );
    },
    getUnique(arr, key) {
      return arr.reduce((acc, item) => {
        return !acc.includes(item[key]) && item[key] !== null ? [...acc, item[key]] : acc;
      }, []);
    },
    getTBD(arr) {
      let isTBD = 0,
        isNONTBD = 0;

      for (const item of arr) {
        let eooContainerNo = item.eooContainerNo,
          eooReleaseId = item.eooReleaseId,
          eooEquipmentCreatedDate = item.eooEquipmentCreatedDate;

        if (isTBD > 0 && isNONTBD > 0) {
          return "MIXED";
        }
        if (eooContainerNo && eooReleaseId) {
          if (item.dateCreated > eooEquipmentCreatedDate) {
            isNONTBD += 1;
          } else {
            isTBD += 1;
          }
        } else {
          if (eooContainerNo) {
            isNONTBD += 1;
          } else if (eooReleaseId) {
            isTBD += 1;
          }
        }
      }

      if (isNONTBD > 0 && isNONTBD !== arr.length) {
        return "MIXED";
      } else if (isTBD === 0 && isNONTBD === 0) {
        return "TBD";
      } else if (isTBD === 0) {
        return "DETERMINED";
      } else return "TBD";
    },
  },
};
</script>

<style></style>
