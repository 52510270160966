<template>
  <TableLayout>
    <depotTermination-modal
      :show="show"
      :itemData="itemData"
      :title="modalTitle"
      :paperRows="paperRows"
      @showModal="showModal"
      @saveDepotTermination="save"
    ></depotTermination-modal>
    <template v-slot:panel>
      <panel-action
        @addDepotTermination="addDepotTermination"
        @changeSearchValue="changeSearchValue"
      ></panel-action>
    </template>

    <template v-slot:table>
      <depotTermination-table
        @showModal="showModal"
        :depotTerminations="filteredDepotTerminations"
      ></depotTermination-table>
    </template>
  </TableLayout>
</template>

<script>
import DepotTerminationTable from "@/components/DepotTermination/Table.vue";
import PanelAction from "@/components/DepotTermination/PanelAction.vue";
import DepotTerminationModal from "@/components/DepotTermination/DepotTerminationModal.vue";
import TableLayout from "@/layout/TableLayout";
import { search } from "@/utils/panelAction.js";
import { header } from "@/helpers/mocks/depottermination.js";

import {
  get_depot_terminations,
  create_depot_termination,
  update_toggle_depot_termination,
} from "@/helpers/api/indexv2.js";

import { depotTerminationFormDataRows } from "@/helpers/mocks/depottermination.js";
import { mapState, mapMutations, mapActions } from "vuex";
import { DateTime } from "luxon";
import { depotTerminationData } from "@/helpers/mocks/depottermination.js";

export default {
  name: "DepotTermination",
  components: {
    DepotTerminationTable,
    PanelAction,
    DepotTerminationModal,
    TableLayout,
  },

  data() {
    return {
      show: false,
      modalTitle: "",
      itemData: {},
      searchValue: "",
      paperRows: [],
      tab: null,
      header,
    };
  },
  computed: {
    ...mapState(["depotTerminations"]),

    tabValues() {
      return [
        { title: "Depot Terminations" },
        {
          title: "Depots",
        },
      ];
    },

    filteredDepotTerminations() {
      return search({
        searchInput: this.searchValue,
        header: this.header,
        arr: this.depotTerminations,
      });
    },
  },
  methods: {
    ...mapMutations(["setSnackbar", "setSpinnerShow"]),
    ...mapActions(["saveDepotTermination"]),
    showModal(data) {
      if (data) {
        let { item, title } = data;

        if (item) this.itemData = Object.assign({}, item);
        if (!this.show) {
          this.paperRows = depotTerminationFormDataRows;
          this.modalTitle = title;
        }
      }
      this.show = !this.show;
    },
    addDepotTermination() {
      this.itemData = {
        FullName: "",
        PointCode: "",
        PoolLocation: "",
        TerminatesAtDepot: "",
        PassthroughAll: "",
        EffectiveFrom: "",
        EffectiveTo: "",
      };

      this.paperRows = depotTerminationFormDataRows;
      this.modalTitle = "New Depot Termination";
      this.show = true;
    },
    async save(flagNew) {
      console.log("this.itemData", this.itemData);

      await this.saveDepotTermination({ flagNew, data: this.itemData });

      this.show = false;
    },
    changeSearchValue(searchValue) {
      this.searchValue = searchValue;
    },
  },
};
</script>

<style></style>
