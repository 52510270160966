<template>
  <div>
    <DepotAllSaleTable />
  </div>
</template>

<script>
import DepotAllSaleTable from "@/components/DepotAllSale/Table.vue";
export default {
  components: {
    DepotAllSaleTable,
  },
};
</script>

<style lang="scss" scoped></style>
