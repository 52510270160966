<template>
  <v-container class="ma-0 pa-3 fill-height insights-index" fluid>
    <v-row class="w-100 fill-height">
      <v-col cols="12" class="pa-1 px-5" style="heigth: 90%">
        <Tabs
          :tab="tab"
          :tabValues="items"
          @changeTab="changeTab"
          :classes="`elevation-0 gap font-weight-bold`"
          :btnclasses="'dark-blue rounded-t-lg rounded-b-0 font-weight-light white--text font-weight-bold'"
          :classActive="'primary white--text'"
        />
        <component :is="tabComponent" />
        <!-- <v-tabs v-model="tab" class="tabs">
          <v-tab v-for="item in items" :key="item.tab" class="tab-text pa-1">
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="pa-0 fill-height">
          <v-tab-item v-for="item in items" :key="item.tab">
            <component :is="item.content"></component>
          </v-tab-item>
        </v-tabs-items> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Dashboard1 from "@/views/Insights/dashboard1.vue";
import Dashboard2 from "@/views/Insights/dashboard2.vue";
import Tabs from "@/components/General/Tabs.vue";

export default {
  components: {
    Dashboard1,
    Dashboard2,
    Tabs,
  },
  computed: {
    tabComponent() {
      switch (this.tab) {
        case 0:
          return Dashboard1;
        case 1:
          return Dashboard2;
      }
    },
  },
  data() {
    return {
      tab: 0,
      items: [
        { title: "Analytics", content: Dashboard1 },
        { title: "Heatmap", content: Dashboard2 },
      ],
    };
  },
  methods: {
    changeTab(value) {
      this.tab = value;
    },
  },
};
</script>

<style scoped>
.tab-text {
  font-size: 70%;
  border-width: 1px 0px;
  border-color: #3284f4;
  border-style: solid;
}
.tabs {
  flex: none !important;
  /* display: flex;
  width: 3%; */
}

.w-100 {
  width: 100%;
}
</style>
