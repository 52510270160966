var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{staticStyle:{"height":"100%","width":"100%"},attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ invalid, errors }){return [_c('v-row',{staticStyle:{"height":"100%","width":"100%"}},[_c('v-col',{staticClass:"fill-height",attrs:{"cols":"12"}},[_c('v-row',[_c('NewOrderActions',{attrs:{"auctionFlag":"true","invalid":_vm.flagAuctionUpdate
              ? invalid || !!_vm.getErrorAuctionForm(errors).length || _vm.getErrorDepotTable()
              : invalid ||
                !!_vm.getErrorGeneralData(_vm.UI[0].props.fieldsForm, errors).length ||
                !!_vm.getErrorAuctionForm(errors).length ||
                _vm.getErrorDepotTable(),"flagModalUnreCust":_vm.flagModalUnreCust,"tableSelected":_vm.tableSelected},on:{"createNewAuction":_vm.createNewAuction,"saveAsDraft":_vm.saveAsDraft,"loadDraft":_vm.loadDraft,"deleteDraft":_vm.deleteSavedAuction,"loadLots":function($event){_vm.flagLoadLots = true},"updateAuction":_vm.updateAuction}})],1),_c('v-row',[_c('v-col',[_c('v-expansion-panels',{attrs:{"accordion":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.UI),function({ header_title, component, props, events, id },i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(
                      id === 1
                        ? _vm.getErrorGeneralData(props.fieldsForm, errors).length
                        : _vm.getErrorDepotTable() || _vm.getErrorAuctionForm(errors).length
                    )?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert-circle ")]):_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")])]},proxy:true}],null,true)},[_c('v-row',{staticClass:"justify-center"},[_c('span',{staticClass:"text-h6 font-weight-black"},[_vm._v(" "+_vm._s(header_title)+" ")])])],1),_c('v-expansion-panel-content',[_c('keep-alive',[_c(component,_vm._g(_vm._b({tag:"component",attrs:{"errors":errors}},'component',props,false),events))],1)],1)],1)}),1)],1)],1)],1),(_vm.showModalPreviewAuction)?_c('ModalPreviewAuction',{attrs:{"show":_vm.showModalPreviewAuction,"title":"Details","objPreview":_vm.objPreview,"flagAuctionUpdate":_vm.flagAuctionUpdate},on:{"changeFlagModalPreviewAuction":_vm.changeFlagModalPreviewAuction,"createAuction":_vm.createAuctionPreviewer,"updateAuction":_vm.updateAuctionPreviewer}}):_vm._e(),(_vm.flagLoadLots)?_c('ModalLoadLots',{attrs:{"title":"Load Lots","show":_vm.flagLoadLots},on:{"loadLots":_vm.loadLots,"changeFlagLoadLots":function($event){_vm.flagLoadLots = false}}}):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }