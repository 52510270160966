<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="10" class="white">
        <router-view> </router-view>
        <!-- <Summary /> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import Form from "@/components/CustomerDistribution/Form.vue";
export default {
  components: {
    Form,
  },
  async created(){
    await this.getList()
  },
  computed: {
    ...mapState({}),
    isComponent() {
      return this.newListFlag ? Form : Summary;
    },
  },
  data() {
    return {
      newListFlag: true,
    };
  },

  methods: {
    ...mapMutations({
      setSpinnerShow: "setSpinnerShow",
      setSearchAuction: "moduleAuctions/setSearchAuction",
      setAuctionFormFlag: "moduleQtySumm/setAuctionFormFlag",
    }),
    ...mapActions({
      getList: "moduleCustomerDistribution/getList",
    }),
    setNewListFlag(value) {
      this.newListFlag = value;
    },
    async refresh() {
      await this.getAuctions({ offset: this.offset, status: this.tabSelected });
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  height: 40px !important;
}
input {
  height: 20px;
}
</style>
