<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <NewOrder />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Table from "@/components/OM/Table";

import NewOrder from "@/components/OM/NewOrder/NewOrder.vue";

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    NewOrder,
  },
  async created() {},
  computed: {
    ...mapState({}),
    ...mapGetters({}),
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions({}),
  },
};
</script>

<style></style>
