<template>
  <TableLayout>
    <DepotManageModal
      :show="show"
      :itemData="itemData"
      :title="modalTitle"
      :paperRows="paperRows"
      :statusData="statusData"
      @showModal="showModal"
      @saveDepot="save"
    ></DepotManageModal>

    <MultipleContactsModal
      :show="showModalMultipleContacts"
      :itemData="itemData"
      :title="modalTitle"
      @showModal="showModal"
    />

    <template v-slot:panel>
      <PanelActionManage
        @addDepotManage="adddepotManage"
        @changeSearchValue="changeSearchValue"
      />
    </template>
    <template v-slot:table>
      <DepotManageTable :depotManage="depotItems" @showModal="showModal">
      </DepotManageTable>
    </template>
  </TableLayout>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import { search } from "@/utils/panelAction.js";
import { header } from "@/helpers/mocks/depotmanage.js";
import { depotManageFormDataRows } from "@/helpers/mocks/depotmanage.js";

import DepotManageTable from "@/components/DepotManage/Table.vue";
import MultipleContactsModal from "@/components/DepotManage/MultipleContactsModal.vue";
import PanelActionManage from "@/components/DepotManage/PanelAction.vue";
import DepotManageModal from "@/components/DepotManage/DepotManageModal.vue";
import TableLayout from "@/layout/TableLayout";

import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";
export default {
  name: "DepotManage",
  components: {
    DepotManageTable,
    PanelActionManage,
    DepotManageModal,
    TableLayout,
    MultipleContactsModal,
  },
  data() {
    return {
      show: false,
      showModalMultipleContacts: false,
      modalTitle: "",
      itemData: {},
      searchValue: "",
      paperRows: [],
      depotItems: [],
      header,
      statusData: {
        EffectiveFrom: "",
        EffectiveTo: "",
      },
    };
  },
  async created() {
    await this.loadData();
  },
  computed: {
    ...mapState(["depotManage"]),
  },
  watch: {
    async depotManage() {
      if (this.searchValue != "") {
        this.filteredDepots();
      } else {
        this.depotItems = [...this.depotManage];
      }
    },
    async searchValue() {
      if (this.searchValue != "") {
        this.filteredDepots();
      } else {
        this.depotItems = [...this.depotManage];
      }
    },
  },
  methods: {
    ...mapMutations(["setSnackbar", "setSpinnerShow", "setDepotManage"]),
    ...mapActions({
      savedepotManage: "savedepotManage",
      getAllManageDepot: "getAllManageDepot",
      updateDepotDate: "moduleDepotManage/updateDepotDate",
      saveDepotDate: "moduleDepotManage/saveDepotDate",
    }),
    async loadData() {
      this.$store.commit("setSpinnerShow", true);
      this.setDepotManage([]);
      await this.getAllManageDepot();
      this.$store.commit("setSpinnerShow", false);
    },
    showModal(data) {
      if (data) {
        let { item, title } = data;

        switch (title) {
          case "Multiple Contacts":
            this.showModalMultipleContacts = !this.showModalMultipleContacts;
            this.show = false;
            this.modalTitle = "Add New Contact";
            if (item) this.itemData = Object.assign({}, item);
            break;
          case "Update Contact":
            this.showModalMultipleContacts = !this.showModalMultipleContacts;
            this.show = false;
            this.modalTitle = "Update Contact";
            if (item) {
              this.itemData = Object.assign({}, item);
            }
            break;

          default:
            this.showModalMultipleContacts = false;

            if (item) {
              this.itemData = Object.assign({}, item);
              let { EffectiveFrom, EffectiveTo } = item;
              this.statusData["EffectiveFrom"] = EffectiveFrom
                ? parseFromIsoToFormat(EffectiveFrom, "yyyy-LL-dd")
                : "";
              this.statusData["EffectiveTo"] = EffectiveTo
                ? parseFromIsoToFormat(EffectiveTo, "yyyy-LL-dd")
                : "";
            }
            if (!this.show) {
              this.paperRows = depotManageFormDataRows;
              this.modalTitle = title;
            }
            this.show = !this.show;
            break;
        }
      } else {
        this.show = false;
        this.showModalMultipleContacts = false;
      }
    },
    adddepotManage() {
      this.itemData = {
        FullName: "",
        PointCode: "",
        PoolLocation: "",
        TerminatesAtDepot: "",
        PassthroughAll: "",
        EffectiveFrom: "",
        EffectiveTo: "",
        access_requirements: "",
      };

      this.statusData["EffectiveFrom"] = "";
      this.statusData["EffectiveTo"] = "";

      this.paperRows = depotManageFormDataRows;
      this.modalTitle = "New Depot";
      this.show = true;
      this.showModalMultipleContacts = false;
    },
    async save(flagNew) {
      await this.savedepotManage({
        flagNew,
        data: this.itemData,
        statusData: this.statusData,
      });

      this.show = false;

      const searchValue_B = this.searchValue;
      this.searchValue = "";

      setTimeout(() => {
        this.searchValue = searchValue_B;
      }, 200);
    },
    changeSearchValue(searchValue) {
      this.searchValue = searchValue;
    },
    filteredDepots() {
      this.depotItems = search({
        searchInput: this.searchValue,
        header: this.header,
        arr: this.depotItems,
      });
    },
  },
};
</script>

<style></style>
