<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" class="auctions">
        <router-view> </router-view>
        <!-- <Summary /> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},
  async created() {},
  computed: {},
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.auctions {
}
</style>
