<template>
  <v-row>
    <v-col cols="12" class="py-2 elevation-5">
      <v-row class="elevation-5 px-5 py-2" align="center">
        <v-col class="px-12" cols="auto">
          <span
            class="black--text text-no-wrap text-h5 font-weight-bold text-capitalize"
            style="letter-spacing: 2px !important"
            >Customer Distribution Lists</span
          >
        </v-col>

        <v-col cols="auto" class="ml-auto">
          <v-btn
            color="primary"
            class="rounded"
            @click="$router.push('/customerlist/new-list')"
          >
            New List
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="fill-height">
      <Table :expanded="true" />
    </v-col>
  </v-row>
</template>

<script>
import Table from "@/components/CustomerDistribution/Table.vue";

export default {
  components: {
    Table,
  },
};
</script>

<style></style>
