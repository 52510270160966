<template>
  <ValidationObserver
    v-slot="{ invalid, errors }"
    tag="div"
    style="height: 100%; width: 100%"
  >
    <v-row style="height: 100%; width: 100%">
      <v-col cols="12" class="fill-height">
        <v-row>
          <NewOrderActions
            auctionFlag="true"
            @createNewAuction="createNewAuction"
            @saveAsDraft="saveAsDraft"
            @loadDraft="loadDraft"
            @deleteDraft="deleteSavedAuction"
            @loadLots="flagLoadLots = true"
            @updateAuction="updateAuction"
            :invalid="
              flagAuctionUpdate
                ? invalid || !!getErrorAuctionForm(errors).length || getErrorDepotTable()
                : invalid ||
                  !!getErrorGeneralData(UI[0].props.fieldsForm, errors).length ||
                  !!getErrorAuctionForm(errors).length ||
                  getErrorDepotTable()
            "
            :flagModalUnreCust="flagModalUnreCust"
            :tableSelected="tableSelected"
          />
        </v-row>
        <v-row>
          <v-col>
            <v-expansion-panels accordion v-model="panel">
              <v-expansion-panel
                v-for="({ header_title, component, props, events, id }, i) in UI"
                :key="i"
              >
                <v-expansion-panel-header disable-icon-rotate>
                  <v-row class="justify-center">
                    <span class="text-h6 font-weight-black">
                      {{ header_title }}
                    </span>
                  </v-row>
                  <template v-slot:actions>
                    <v-icon
                      color="error"
                      v-if="
                        id === 1
                          ? getErrorGeneralData(props.fieldsForm, errors).length
                          : getErrorDepotTable() || getErrorAuctionForm(errors).length
                      "
                    >
                      mdi-alert-circle
                    </v-icon>
                    <v-icon color="success" v-else> mdi-check </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <keep-alive>
                    <component
                      :is="component"
                      v-bind="props"
                      :errors="errors"
                      v-on="events"
                    ></component>
                  </keep-alive>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
      <ModalPreviewAuction
        v-if="showModalPreviewAuction"
        :show="showModalPreviewAuction"
        @changeFlagModalPreviewAuction="changeFlagModalPreviewAuction"
        @createAuction="createAuctionPreviewer"
        @updateAuction="updateAuctionPreviewer"
        title="Details"
        :objPreview="objPreview"
        :flagAuctionUpdate="flagAuctionUpdate"
      />
      <ModalLoadLots
        title="Load Lots"
        v-if="flagLoadLots"
        :show="flagLoadLots"
        @loadLots="loadLots"
        @changeFlagLoadLots="flagLoadLots = false"
      />
    </v-row>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import NewOrderActions from "@/components/OM/AllOrders/NewOrderActions.vue";
import CreateAuctionForm from "@/components/InventoryQtySumm/CreateAuctionForm.vue";
import AuctionTable from "@/components/InventoryQtySumm/AuctionTable.vue";
import AuctionGeneralData from "@/components/InventoryQtySumm/AuctionGeneralData.vue";
import ModalPreviewAuction from "@/components/Auctions/ModalPreviewAuction.vue";
import { FromFormatToTimeStamp } from "@/helpers/mocks/dates.js";
import { available_container_no } from "@/helpers/api/Orders/index.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ModalLoadLots from "@/components/Auctions/ModalLoadLots.vue";
import WrapperDepots from "@/components/Auctions/WrapperDepots.vue";
export default {
  components: {
    NewOrderActions,
    CreateAuctionForm,
    AuctionTable,
    AuctionGeneralData,
    ValidationObserver,
    ModalPreviewAuction,
    ModalLoadLots,
    WrapperDepots,
  },
  async created() {
    const { id } = this.$route.query;
    if (id) {
      this.flagAuctionUpdate = true;
    }
    await this.getDataForAuction();
    if (this.batchInfo) {
      let item = this.batchInfo;
      this.tableSelected.push(item);
      this.$set(this.objAuctionForm, item.group, [{}]);
      let res = await this.getContainersNoAvailable(item);

      this.$set(this.containersNo, item.group, res.data);
    }

    // if (id) {
    //   this.flagAuctionUpdate = true;
    // let data = await this.getAuctionInfo({
    //   id,
    // });
    // const tableSelected = data.map(
    //   (x) => `${x.Location}${x.CntrSize}${x.CntrType}${x.CntrCondition}`
    // );
    // this.tableSelected = this.inventoryQtySumms.filter(
    //   (x) => tableSelected.includes(x.group) && x.QtyOnHandAvailable > 0
    // );
    // const res = await Promise.all(
    //   this.tableSelected.map((x) => {
    //     return this.getContainersNoAvailable(x);
    //   })
    // );
    // this.tableSelected.forEach((x, index) => {
    //   this.$set(this.containersNo, x.group, res[index].data);
    // });
    // this.objAuctionForm = data.reduce((acc, item) => {
    //   if (
    //     this.tableSelected.filter(
    //       (x) =>
    //         x.group ===
    //         `${item.Location}${item.CntrSize}${item.CntrType}${item.CntrCondition}`
    //     ).length
    //   ) {
    //     let containers = item.containers
    //       ? item.containers.map((x) => {
    //           return {
    //             EquipmentID: x.ContainerNo,
    //             ...x,
    //           };
    //         })
    //       : [];
    //     acc[
    //       `${item.Location}${item.CntrSize}${item.CntrType}${item.CntrCondition}`
    //     ] = acc[`${item.Location}${item.CntrSize}${item.CntrType}${item.CntrCondition}`]
    //       ? [
    //           ...acc[
    //             `${item.Location}${item.CntrSize}${item.CntrType}${item.CntrCondition}`
    //           ],
    //           {
    //             min_price: Number(item.minPrice),
    //             quantity: item.qty,
    //             name: item.name,
    //             container_no: containers,
    //           },
    //         ]
    //       : [
    //           {
    //             min_price: Number(item.minPrice),
    //             quantity: item.qty,
    //             name: item.name,
    //             container_no: containers,
    //           },
    //         ];
    //   }
    //   return acc;
    // }, {});
    // }
  },
  computed: {
    ...mapState({
      batchInfo: (state) => state.moduleAuctions.batchInfo,
      depotManage: (state) => state.depotManage,
      distributionList: (state) => state.moduleCustomerDistribution.lists,
      savedAuction: (state) => state.moduleAuctions.savedAuction,
      inventoryQtySumms: (state) => state.moduleQtySumm.inventoryQtySumms,
    }),
    ...mapGetters({
      getInventoryQtySummsAvailables: "moduleQtySumm/getInventoryQtySummsAvailables",
      getGoodRefeersWithGroup: "moduleQtySumm/getGoodRefeersWithGroup",
      getBadRefeersWithGroup: "moduleQtySumm/getBadRefeersWithGroup",
    }),
    UI() {
      return !this.flagAuctionUpdate
        ? [
            {
              id: 1,
              header_title: "General Data",
              component: "AuctionGeneralData",
              props: {
                obj: this.objGeneralData,
                distributionList: this.distributionList,
                flagModalUnreCust: this.flagModalUnreCust,
                fieldsForm: [
                  { label: "name", value: "name", rules: "required" },
                  { label: "customers", value: "customers", rules: "required" },
                  { label: "Unregistered", value: "unregisterd" },
                  { label: "start day", value: "start_day", rules: "required" },
                  { label: "start time", value: "time_start_day", rules: "required" },
                  { label: "End day", value: "end_day", rules: "required" },
                  { label: "End time", value: "time_end_day", rules: "required" },
                  { label: "Comments", value: "comments" },
                  { label: "Bypass invites?", value: "bypass" },
                ],
              },
              events: { changeFlagModalUnreCust: this.changeFlagModalUnreCust },
            },
            {
              id: 2,
              header_title: "Depot Data",
              component: "WrapperDepots",
              props: {
                selectedProp: this.tableSelected,
                tableSelected: this.tableSelected,
                objAuctionForm: this.objAuctionForm,
                containersNo: this.containersNo,
              },
              events: {
                updateSelected: this.updateSelected,
                addNewLot: this.addNewLot,
              },
            },
          ]
        : [
            {
              id: 2,
              header_title: "Depot Data",
              component: "WrapperDepots",
              props: {
                selectedProp: this.tableSelected,
                tableSelected: this.tableSelected,
                objAuctionForm: this.objAuctionForm,
                containersNo: this.containersNo,
              },
              events: {
                updateSelected: this.updateSelected,
                addNewLot: this.addNewLot,
              },
            },
          ];
    },
  },
  data() {
    return {
      panel: [],
      flagAuctionUpdate: false,
      missingFromACL: [],
      showModalPreviewAuction: false,
      objPreview: null,
      objGeneralData: {
        customers: [],
        emailList: [],
        end_day: "",
        start_day: "",
        name: "",
        time_start_day: "",
        time_end_day: "",
        menus_time_tart_day: false,
        menu_time_end_day: false,
      },
      tableSelected: [],

      objAuctionForm: {},
      containersNo: {},
      flagModalUnreCust: false,
      flagLoadLots: false,
      rules: [],
    };
  },

  methods: {
    ...mapActions({
      createAuction: "moduleAuctions/createAuction",
      updateAuctionStore: "moduleAuctions/updateAuctionLineItems",
      getAuctionInfo: "moduleAuctions/getAuctionInfo",
      getContainersNoAvailable: "moduleAuctions/getContainersNoAvailable",
      postSavedAuction: "moduleAuctions/postSavedAuction",
      getAllManageDepot: "getAllManageDepot",
      getList: "moduleCustomerDistribution/getList",
      getSavedAuction: "moduleAuctions/getSavedAuction",
      deleteSavedAuction: "moduleAuctions/deleteSavedAuction",
      getDataForAuction: "moduleAuctions/getDataForAuction",
    }),
    ...mapMutations({
      setAuctionFormFlagStore: "moduleQtySumm/setAuctionFormFlag",
    }),
    getErrorAuctionForm(errors) {
      const errorsFiltered = Object.keys(errors).filter(
        (x) => x.includes("price") || x.includes("quantity")
      );

      return errorsFiltered.length
        ? errorsFiltered.filter((x) => errors[x].length)
        : ["1"];
    },
    getErrorDepotTable() {
      return !this.tableSelected.length;
    },
    getErrorGeneralData(fields, errors) {
      if (fields) return fields.filter((x) => !errors[x.label] || errors[x.label].length);

      return [];
    },
    async updateSelected({ item, value }) {
      if (value) {
        this.tableSelected.push(item);
        this.$set(this.objAuctionForm, item.group, [{}]);

        if (!this.containersNo.hasOwnProperty(item.group)) {
          let res = await available_container_no({
            status: 1,
            depot: item.Depot,
            size: item.Size,
            cndtn: item.Cndtn,
            type: item.Type,
          });

          this.$set(this.containersNo, item.group, res.data);
        }
      } else {
        delete this.objAuctionForm[item.group];

        this.tableSelected = this.tableSelected.filter((x) => x.group !== item.group);

        delete this.containersNo[item.group];
      }
    },

    ///////////////////////////////
    async loadLots(json) {
      const data = JSON.parse(json);
      console.log(data);
      let groups = [
        ...new Set(data.map((x) => `${x.Location}${x.Size}${x.Type}${x.Condition}`)),
      ];

      this.tableSelected = this.getInventoryQtySummsAvailables.filter((value) => {
        return groups.includes(value.group);
      });

      let obj = {};

      data.map((x) => {
        let group = `${x.Location}${x.Size}${x.Type}${x.Condition}`;
        if (obj[group]) {
          obj[group].push({
            min_price: x.minPrice,
            name: x.name,
            comments: x.comments,
            quantity: x.qty,
            container_no: [],
          });
        } else {
          obj[group] = [
            {
              min_price: x.minPrice,
              name: x.name,
              comments: x.comments,
              quantity: x.qty,
              container_no: [],
            },
          ];
        }
        return obj;
      });

      this.objAuctionForm = obj;
      let res = await Promise.all(
        this.tableSelected.map((x) => this.getContainersNoAvailable(x))
      );

      this.tableSelected.forEach((x, index) => {
        this.$set(this.containersNo, x.group, res[index].data);
      });
      console.log(res);
    },

    ////

    addNewLot({ group }) {
      this.lots.push({ group });
    },
    changeFlagModalUnreCust(flag) {
      this.flagModalUnreCust = flag;
    },

    changeFlagModalPreviewAuction(flag) {
      this.showModalPreviewAuction = flag;
    },
    async createAuctionPreviewer() {
      let res = await this.createAuction(this.objPreview);
      if (res) {
        // this.getAuctionInfo({ id: res.id });
        this.$router.push("/auctions");
      }
      this.setAuctionFormFlagStore(false);
      this.objPreview = null;
    },
    async createNewAuction() {
      let obj = {};
      console.log("Sss");
      obj.bypass = this.objGeneralData.bypass;

      obj.comments = this.objGeneralData.comments;
      obj.StartDateTime = FromFormatToTimeStamp(
        `${this.objGeneralData.start_day} ${this.objGeneralData.time_start_day}`,
        "yyyy-LL-dd HH:mm"
      );
      obj.EndDateTime = FromFormatToTimeStamp(
        `${this.objGeneralData.end_day} ${this.objGeneralData.time_end_day}`,
        "yyyy-LL-dd HH:mm"
      );
      obj.name = this.objGeneralData.name;
      obj.customers = [];

      this.objGeneralData.customers.map((x) => {
        if (x.primary_email) {
          let emails = x.primary_email.split(";");
          obj.customers = [
            ...obj.customers,
            {
              customerEntityId: x.organization_id,
              primary_email: emails[0],
            },
          ];
        }
      });

      obj.customers = [...obj.customers, ...this.objGeneralData.emailList];

      obj.lineItems = [];
      await this.getAllManageDepot();

      let self = this;

      self.missingFromACL = [];
      this.tableSelected.map((selected) => {
        let depotName = this.depotManage.filter((x) => selected.DepotId === x.DepotId);

        if (depotName.length == 0) {
          self.missingFromACL.push(selected.Depot);
        }

        if (depotName.length > 0) {
          this.objAuctionForm[selected.group].map((item) => {
            obj.lineItems.push({
              equipmentSize: selected.Size,
              equipmentType: selected.Type,
              equipmentCondition: selected.Cndtn,
              depot: selected.Depot,
              country: selected.CountryAlhpa2,
              state: selected.StateAlpha2,
              city: selected.CityName,
              depotId: selected.DepotId,
              name: item.name,
              minPrice: Number(item.min_price),
              comments: item.comments,
              qty: Number(item.quantity),
              ContainerNos: item.container_no,
              depotName: depotName[0].DepotName,
              access_requirements: depotName[0].access_requirements,
            });
          });
        }
      });
      console.log(this.missingFromACL);

      if (self.missingFromACL.length > 0) {
        prompt(
          "Warning! These depots are missing ACL",
          JSON.stringify(self.missingFromACL)
        );
      } else {
        console.log(this.missingFromACL);
        this.objPreview = obj;
        this.showModalPreviewAuction = true;
      }
    },
    async saveAsDraft() {
      let obj = {};
      obj.objGeneralData = this.objGeneralData;
      obj.tableSelected = this.tableSelected;
      obj.objAuctionForm = this.objAuctionForm;
      obj.containersNo = this.containersNo;
      this.postSavedAuction(obj);
    },
    async loadDraft() {
      this.panel = 0;

      await this.getSavedAuction();
      this.objGeneralData = this.savedAuction.objGeneralData;
      this.tableSelected = this.savedAuction.tableSelected;
      this.objAuctionForm = this.savedAuction.objAuctionForm;
      this.containersNo = this.savedAuction.containersNo;
    },
    async updateAuction() {
      let obj = {};
      obj.lineItems = [];
      await this.getAllManageDepot();

      let self = this;

      self.missingFromACL = [];
      this.tableSelected.map((selected) => {
        let depotName = this.depotManage.filter((x) => selected.DepotId === x.DepotId);

        if (depotName.length == 0) {
          self.missingFromACL.push(selected.Depot);
        }

        if (depotName.length > 0) {
          this.objAuctionForm[selected.group].map((item) => {
            obj.lineItems.push({
              equipmentSize: selected.Size,
              equipmentType: selected.Type,
              equipmentCondition: selected.Cndtn,
              depot: selected.Depot,
              country: selected.CountryAlhpa2,
              state: selected.StateAlpha2,
              city: selected.CityName,
              depotId: selected.DepotId,
              name: item.name,
              minPrice: Number(item.min_price),
              comments: item.comments,
              qty: Number(item.quantity),
              ContainerNos: item.container_no,
              depotName: depotName[0].DepotName,
              access_requirements: depotName[0].access_requirements,
            });
          });
        }
      });

      if (self.missingFromACL.length > 0) {
        prompt(
          "Warning! These depots are missing ACL",
          JSON.stringify(self.missingFromACL)
        );
      } else {
        this.objPreview = obj;
        this.showModalPreviewAuction = true;
      }
    },
    async updateAuctionPreviewer() {
      try {
        await this.updateAuctionStore({
          auctionId: this.$route.query.id,
          data: this.objPreview,
        });

        // this.getAuctionInfo({ id: this.$route.query.id });
        this.$router.push("/auctions");

        this.setAuctionFormFlagStore(false);
        this.objPreview = null;
      } catch (error) {}
    },
  },
};
</script>

<style></style>
