<template>
  <v-container fluid class="px-5 py-0 fill-height">
    <ModalCustomerPayment
      v-if="showCustomerPaymentFlag"
      :show="showCustomerPaymentFlag"
      :docNo="tabDetails.docNo"
      title="Customer Payment"
      @showCustomerPayment="showCustomerPayment"
    />
    <ModalIssueaRelease
      v-if="showIssueReleaseFlag"
      :show="showIssueReleaseFlag"
      :docNo="tabDetails.docNo"
      :tabDetails="tabDetails"
      title="New Record Release Note"
      @showIssueRelease="showIssueRelease"
    />
    <v-row class="ma-0 test" justify="space-between">
      <v-col cols="1" class="pa-0">
        <v-btn class="button elevation-5" @click="returnRouterOrders">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <StepperDetail class="py-2" ref="steppersContainer" />
    <v-row class="py-2">
      <!-- LEFT -->
      <v-col cols="2" class="elevation-5 px-2 fill-height overflow-y-auto">
        <LeftPanel class="dark-blue" />
      </v-col>
      <!-- CENTER -->
      <v-col cols="7" class="elevation-5 fill-height overflow-y-auto" style="gap: 5px">
        <v-row style="gap: 5px">
          <MessageRow v-if="infoMessage" />
          <ItemsPanelAction
            class="dark-blue flex"
            @showCustomerPayment="showCustomerPayment"
            @showIssueRelease="showIssueRelease"
          />
          <v-container fluid class="dark-blue">
            <ItemsOrdersDetails />
            <v-expansion-panels class="expansion-panel dark-blue">
              <v-expansion-panel
                v-for="panel in centerPanels"
                :key="panel.title"
                class="dark-blue py-2"
              >
                <v-expansion-panel-header class="pa-0 px-1">
                  <v-list-item-content
                    class="ma-0 pa-0 dark-blue d-flex fill-height text-center"
                  >
                    <v-list-item-subtitle
                      class="ma-0 primary--text font-weight-black font-weight-black text-h6"
                      tag="span"
                    >
                      <template>
                        {{ panel.title }}
                      </template>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0">
                  <v-container class="px-1 py-0">
                    <component
                      :is="panel.component"
                      :title="panel.title"
                      :tabDetailsB="tabDetails"
                    />
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-row>
      </v-col>
      <!-- RIGHT -->
      <v-col
        cols="3"
        class="elevation-5 px-2 fill-height overflow-y-auto"
        :style="{ maxHeight: '75vh' }"
      >
        <v-expansion-panels
          class="expansion-panel dark-blue"
          :value="panelsOpen"
          multiple
        >
          <v-expansion-panel
            v-for="panel in rightPanels"
            :key="panel.title"
            class="dark-blue py-0 small-text"
          >
            <v-expansion-panel-header class="pa-0 px-1">
              <v-list-item-content
                class="ma-0 pa-0 dark-blue d-flex fill-height text-center"
              >
                <v-list-item-subtitle
                  class="ma-0 primary--text font-weight-black font-weight-black text-h6"
                  tag="span"
                >
                  <template>
                    {{ panel.title }}
                  </template>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-0">
              <v-container class="px-1 py-0">
                <component
                  :is="panel.component"
                  :title="panel.title"
                  :tabDetails="tabDetails"
                  :class="panel.props && panel.props.classes ? panel.props.classes : ''"
                />
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- <v-slide-y-transition> -->
    <v-row v-if="flagAddLineItems">
      <v-col cols="12" class="my-3 pa-5 create-order-table">
        <div style="display: flex; flex-flow: column; max-height: 400px">
          <div>
            <v-btn
              class="ma-2 lighten-4 elevation-5"
              outlined
              small
              style="float: right"
              @click="closeOrderTable"
            >
              Close
            </v-btn>
          </div>
          <CreateOrderTable @formOrderTableSelectedItem="formOrderTableSelectedItem" />
        </div>
      </v-col>
    </v-row>
    <!-- </v-slide-y-transition> -->
  </v-container>
</template>

<script>
import Panel from "@/components/OM/Panel";
import Details from "@/components/OM/Details";
import Tabs from "@/components/OM/Tabs.vue";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import CreateOrderTable from "@/components/InventoryQtySumm/CreateOrderTable.vue";
import LeftPanel from "@/components/OM/Details/LeftPanel/index";
import StepperDetail from "@/components/OM/Details/StepperDetail";
import ItemsPanelAction from "@/components/OM/Details/ItemsPanelAction.vue";
import EquipmentOnOrderSidePanel from "@/components/OM/Details/EquipmentOnOrderSidePanel";
import ModalCustomerPayment from "@/components/OM/ModalCustomerPayment.vue";
import ModalIssueaRelease from "@/components/OM/ModalIssueRelease/ModalIssueaRelease";
import PreviewRelease from "@/components/OM/ModalIssueRelease/PreviewRelease.vue";
import ItemsOrdersDetails from "@/components/OM/Details/ItemsOrdersDetails.vue";
import BillOfSaleDetail from "@/components/OM/Details/BillOfSaleDetail.vue";
import TableActivities from "@/components/OM/TableActivities.vue";
import PaymentDetails from "@/components/OM/Details/PaymentDetails.vue";
import DebuggingPanel from "@/components/OM/Details/DebuggingPanel.vue";
import Release from "@/components/OM/Details/Release.vue";
import TableDocuments from "@/components/OM/TableDocuments.vue";
import Invoice from "@/components/OM/Details/Invoice.vue";
import Comments from "@/components/OM/Details/Comments.vue";
import Releasev1 from "@/components/OM/Details/Releasev1.vue";
import MessageRow from "@/components/OM/Details/MessageRow.vue";
import { gateType } from "@/helpers/gate_buy.js";
export default {
  components: {
    Panel,
    Tabs,
    Details,
    LeftPanel,
    StepperDetail,
    ItemsPanelAction,
    EquipmentOnOrderSidePanel,
    ModalCustomerPayment,
    ModalIssueaRelease,
    PreviewRelease,
    ItemsOrdersDetails,
    BillOfSaleDetail,
    TableActivities,
    PaymentDetails,
    Release,
    Releasev1,
    Invoice,
    Comments,
    MessageRow,
    CreateOrderTable,
  },
  async created() {
    this.resetDetailsPage();

    if (!this.docNo) {
      let docNo = this.$route.params.docNo;
      this.loadDetails(docNo);
    } else {
      this.loadDetails(this.docNo);
    }
  },
  computed: {
    ...mapState({
      tabSelected: (state) => state.moduleOrders.tabSelected,
      allOrders: (state) => state.moduleOrders.allOrders,
      panelsOpen: (state) => state.moduleOrders.moduleDetails.panelsOpen,
      lineItemsState: (state) => state.moduleOrders.moduleDetails.lineItems,
      EOO: (state) => state.moduleOrders.moduleDetails.EOO,
      infoMessage: (state) => state.moduleOrders.moduleDetails.infoMessage,
      flagAddLineItems: (state) => state.moduleOrders.moduleDetails.flagAddLineItems,
      flagAddingDVContainer: (state) =>
        state.moduleOrders.moduleDetails.flagAddingDVContainer,
    }),
    ...mapGetters({
      docNo: "moduleOrders/docNo",
      orderType: "moduleOrders/moduleDetails/orderType",
      orderTypeDesc: "moduleOrders/moduleDetails/orderTypeDesc",
      tabDetails: "moduleOrders/moduleDetails/tabDetails",
    }),
    tabDetails() {
      return this.tabSelected.details;
    },
    rightPanels() {
      if (!this.orderTypeDesc) return [];
      return gateType.order_type_desc_soft === this.orderTypeDesc
        ? [
            {
              title: "Equipment On Order",
              component: EquipmentOnOrderSidePanel,
            },
          ]
        : [
            {
              title: "Equipment On Order",
              component: EquipmentOnOrderSidePanel,
            },
            {
              title: "Bill Of Sale Detail",
              component: BillOfSaleDetail,
            },
            {
              title: "Payment Detail",
              component: PaymentDetails,
              props: {
                classes: "flex-column",
              },
            },

            {
              title: "Release",
              component: Release,
            },
            {
              title: "Invoice",
              component: Invoice,
            },
          ];
    },
  },
  data() {
    return {
      expandedValues: [0],
      steppersContainerHeight: 0,
      details: [],
      lineItems: [
        {
          LineItem: "3",
          Depot: "USMEMCGIA",
          City: "Memphis",
          State: "TN",
          Country: "US",
          Size: "40",
          Type: "HC",
          Condition: "AS IS",
          ReleaseNo: "",
          Qty: "5",
          UnitPrice: "1,500.00",
          Total: "7,500.00",
        },
      ],
      showCustomerPaymentFlag: false,
      showIssueReleaseFlag: false,

      centerPanels: [
        {
          title: "Attachments",
          component: TableDocuments,
          props: {
            details: this.lineItemsState,
          },
        },

        {
          title: "Notes / Comments",
          component: Comments,
        },

        {
          title: "Activity on Order",
          component: TableActivities,
        },

        {
          title: "",
          component: DebuggingPanel,
        },
      ],
    };
  },
  mounted() {
    this.steppersContainerHeight = this.$refs.steppersContainer.$el.clientHeight;
  },
  watch: {
    tabDetails: {
      deep: true,
      handler() {
        if (this.tabDetails.sent === "R" && this.infoMessage === null) {
          this.setInfoMessage({
            panel: 1,
            message: [
              "Please re-issue the Bill Of Sale, line items have changed.",
              "Please go to payment details for refunds and DV.",
            ],
            disabledPanels: [2],
          });
          this.setPanelsOpen([1]);
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      setDetailsStore: "moduleOrders/setDetails",
      setLineItems: "moduleOrders/moduleDetails/setLineItems",
      setPanelsOpen: "moduleOrders/moduleDetails/setPanelsOpen",
      setInfoMessage: "moduleOrders/moduleDetails/setInfoMessage",
      setFlagAddLineItems: "moduleOrders/moduleDetails/setFlagAddLineItems",
      setAddedLineItemSelected: "moduleOrders/moduleDetails/setAddedLineItemSelected",
      setDVContainer: "moduleOrders/moduleDetails/setDVContainer",
      setFlagAddingDVContainer: "moduleOrders/moduleDetails/setFlagAddingDVContainer",
      setAffectedDVContainer: "moduleOrders/moduleDetails/setAffectedDVContainer",
      setNewOrderFlag: "moduleOrders/setNewOrderFlag",
    }),
    ...mapActions({
      getOrder: "moduleOrders/getOrder",
      getsAllOrders: "moduleOrders/getsAllOrders",
      getAllOrdersDetail: "moduleOrders/getAllOrdersDetail",
      getOrderDetail: "moduleOrders/getOrderDetail",
      loadDetails: "moduleOrders/moduleDetails/loadDetails",
      getEOO: "moduleOrders/moduleDetails/getEOO",
      resetDetailsPage: "moduleOrders/moduleDetails/resetDetailsPage",
    }),
    returnRouterOrders() {
      this.setDetailsStore({});
      this.setNewOrderFlag(false);
      this.$router.replace("/orders");
    },
    showCustomerPayment(flag) {
      if (flag) {
        this.setPanelsOpen([2]);
      }
    },
    showIssueRelease(flag) {
      if (flag) {
        this.setPanelsOpen([3]);
      }
    },
    closeOrderTable() {
      this.setFlagAddLineItems(false);
      this.setFlagAddingDVContainer(false);
      this.setAffectedDVContainer(null);
    },
    formOrderTableSelectedItem(item) {
      if (this.flagAddingDVContainer) {
        this.setDVContainer(item);
      } else this.setAddedLineItemSelected(item);
      this.setFlagAddLineItems(false);
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
}
.create-order-table {
  position: absolute;
  bottom: 0;
  z-index: 9999;
}
</style>
