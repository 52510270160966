<template>
  <TableLayout>
    <template v-slot:panel>
      <PanelActions
        @addRow="addingRow = true"
        @refreshTable="refresh"
        @cancel="cancel"
        @save="save"
        @showModalHeader="showModalHeader"
        @showMultiSortModal="setShowMultiSortModal"
        @setChowFilterPanel="setChowFilterPanel"
        @addFiltersData="addFiltersData"
        @showSellInstruccions="showSellInstruccions"
        @removeFilter="removeFilter"
        @addMissingACL="refreshDepots"
        :itemsSelectedWithDepot="itemsSelectedWithDepot"
        :refreshFlag="refreshFlag"
        :loading="loading"
        :addingRow="addingRow"
        :filters="filters"
      ></PanelActions>
    </template>
    <template v-slot:table>
      <v-container fluid py-0 px-2 fill-height>
        <v-row class="ma-0 pa-0">
          <v-col
            v-for="(value, index) in tabValues"
            :key="value.title"
            class="pa-0 fill-height"
            style="flex-grow: 0"
          >
            <v-btn
              small
              class="px-5 py-2 ma-0 primary--text rounded-0 font-weight-bold"
              :class="
                value.title === tab.title
                  ? 'primary dark_blue--text bold-text'
                  : ''
              "
              dense
              block
              @click="tabClicked({ index, value })"
            >
              {{ value.title }}
              <v-icon
                v-if="value.deleteButton"
                right
                dark
                class="red--text accent-4"
                @click="removeTab(index)"
              >
                mdi-close-octagon
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <grid1 v-if="tab.grid" v-bind="tabProps" :tabTitle="tab.title" />
        <modal-sale-instructions
          v-else
          :itemsSelectedWithDepot="itemsSelectedWithDepot"
          :filters="filters"
        />
      </v-container>
    </template>

    <v-slide-x-transition>
      <multi-sort-modal
        :show="showMultiSort"
        @cancelModal="setShowMultiSortModal"
      >
      </multi-sort-modal>
    </v-slide-x-transition>
    <v-slide-x-transition>
      <filter-panel
        v-if="showFilterPanel"
        class="float-right"
        :filterInfo="filterInfo"
        :filters="filters"
        @setChowFilterPanel="setChowFilterPanel"
        @addFilter="addFilter"
        @removeFilter="removeFilter"
        @clearFilters="clearFilters"
        @updateFilterInfo="updateFilterInfo"
      ></filter-panel>
    </v-slide-x-transition>
    <v-slide-x-transition>
      <inbound-panel class="float-right" v-if="inboundPanelFlag" />
    </v-slide-x-transition>
    <modal-header
      :show="modalHeader"
      @showModalHeader="showModalHeader"
    ></modal-header>
  </TableLayout>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import moduleInventory from "@/store/modules/inventory/index.js";
import {
  header,
  header2_main,
  header2_expanded,
} from "@/helpers/mocks/inventory.js";
import PanelActions from "@/components/Inventory/PanelActions.vue";
import FilterPanel from "@/components/Inventory/FilterPanel.vue";
import ModalHeader from "@/components/Inventory/ModalHeader.vue";
import MultiSortModal from "@/components/Inventory/MultiSortModal.vue";
import Grid1 from "@/components/Inventory/Grid/Grid1.vue";
import InboundPanel from "@/components/Inventory/InboundPanel.vue";
import ModalSaleInstructions from "@/components/Inventory/ModalSaleInstructions.vue";
import { DATES, ENABLE_DATES } from "@/helpers/mocks/dates.js";
import { DateTime } from "luxon";
import TableLayout from "@/layout/TableLayout";
import Pagination from "@/components/Inventory/Pagination.vue";

export default {
  name: "Inventory",
  components: {
    PanelActions,
    FilterPanel,
    ModalHeader,
    MultiSortModal,
    Grid1,
    InboundPanel,
    ModalSaleInstructions,
    TableLayout,
    Pagination,
  },

  created() {
    if (!this.$store.hasModule("moduleInventory"))
      this.$store.registerModule("moduleInventory", moduleInventory);
    this.loading = true;
    window.addEventListener("keyup", this.keyUp);
    this.getOrganizations();

    this.updateDataTable({ filters: this.filters });

    this.loading = false;
  },
  computed: {
    ...mapState([
      "headerGridValues",
      "inventoryFilteredColumnFlag",
      "changedItems",
      "inventoryDataFiltered",
    ]),
    ...mapState({
      inboundPanelFlag: (state) => state.moduleInventory.inboundPanelFlag,
      selectedRows: (state) => state.moduleInventory.selectedRows,
    }),
    itemsSelectedWithDepot() {
      let itemsSelectedWithDepot = [];

      try {
        this.selectedRows.map((id) => {
          let index = this.inventoryDataFiltered.findIndex(
            (item) => item.id === id,
          );

          if (index > -1 && this.inventoryDataFiltered[index].Depot) {
            itemsSelectedWithDepot.push(this.inventoryDataFiltered[index]);
          }
        });
      } catch (error) {
        console.log("itemsSelectedWithDepot ERROR:", error);
      }

      return itemsSelectedWithDepot;
    },
    ...mapGetters(["getLastSavedFilter"]),
    // gridContainerHeight() {
    //   return this.$refs.gridContainer.clientHeight;
    // },

    tabProps() {
      let props = {};
      switch (this.tab.title) {
        case "Main":
          props = {
            header: this.headerGridValues,
            addingRow: this.addingRow,
            cancelFlag: this.cancelFlag,
            saveFlag: this.saveFlag,
            // selectedRows: this.selectedRows,
            switchColumns: true,
            expandedTable: false,
            filters: this.filters,
          };
          break;
        case "On Hand": {
          props = {
            header: this.header2_main,
            header_expanded: this.header2_expanded,
            addingRow: this.addingRow,
            cancelFlag: this.cancelFlag,
            saveFlag: this.saveFlag,
            // selectedRows: this.selectedRows,
            switchColumns: false,
            expandedTable: true,
            filters: this.filters,
          };
        }
      }

      return props;
    },
  },
  destroyed() {
    this.setInventorySearchString("");
    this.setInventoryFilteredColumnData([]);
    this.setInventoryFilteredColumnFlag(false);
    window.removeEventListener("keyup", this.keyUp);
    if (this.$store.hasModule("moduleInventory"))
      this.$store.unregisterModule("moduleInventory");
  },
  data() {
    return {
      tab: { title: "Main", grid: true, deleteButton: false },
      addingRow: false,
      refreshFlag: false,
      loading: false,
      modalHeader: false,
      showMultiSort: false,
      showFilterModal: false,
      showFilterPanel: false,
      cancelFlag: false,
      filterInfo: {},
      saveFlag: false,
      // selectedRows: [], //items enable for edit
      header2_main,
      header2_expanded,
      filters: [],
      header: header.slice(1),
      // saleInstruccionsFlagModal: false,
      tabValues: [
        { title: "Main", grid: true, deleteButton: false },
        // { title: "On Hand", grid: true, deleteButton: false },
        // { title: "Sale Instructions", grid: false, deleteButton: true },
      ],
    };
  },
  methods: {
    ...mapMutations([
      "setChangedItems",
      "setInventorySearchString",
      "setInventoryFilteredColumnFlag",
      "setInventoryFilteredColumnData",
      "moduleInventory/setSelectedRows",
    ]),
    ...mapActions([
      "updateDataTable",
      "addMissingACL",
      "getOrganizations",
      "moduleInventory/getFlagsInstructions",
    ]),
    tabClicked({ index, value }) {
      this.tab = value;
    },
    removeTab(index) {
      this.tab = this.tabValues[0];
      this.tabValues.splice(index, 1);
    },
    showSellInstruccions() {
      let index = this.tabValues.findIndex(
        ({ title }) => title === "Sale Instructions",
      );

      if (index === -1) {
        this.tabValues.push({
          title: "Sale Instructions",
          grid: false,
          deleteButton: true,
        });
        index = this.tabValues.length - 1;
      }

      this.tab = this.tabValues[index];
    },
    ///*******************************************//
    setShowMultiSortModal(value) {
      this.showMultiSort = value;
    },
    ///*******************************************//
    setChowFilterPanel() {
      this.showFilterPanel = !this.showFilterPanel;
    },
    ///*******************************************//
    showModalHeader(show) {
      this.modalHeader = show;
    },
    ///*******************************************//
    cancel() {
      this.cancelFlag = !this.cancelFlag;
      this.addingRow = false;
      this.refreshFlag = false;
      this.loading = false;
      this["moduleInventory/setSelectedRows"]([]);
      this.setChangedItems([]);
    },
    refresh() {
      this.cancel();
      this.updateDataTable({ filters: this.filters });
    },
    ///*******************************************//
    async save() {
      this.saveFlag = !this.saveFlag;
      this.addingRow = false;
      this.refreshFlag = false;
      this.loading = false;
      this["moduleInventory/setSelectedRows"]([]);
    },

    ///*******************************************//
    addFilter({ values, operator, conector }) {
      let { value, title } = values;
      this.filters.push({
        title,
        field: value,
        value: "",
        operator,
        conector,
      });
    },
    ///*******************************************//
    clearFilters() {
      this.filters = [];
      this.filterInfo = {};
      this.setInventoryFilteredColumnFlag(false);
      this.updateDataTable({ filters: this.filters });
    },
    ///*******************************************//
    addFiltersData(filter) {
      this.filters = filter.filters.map((value) => {
        console.log(value);
        let filterValue = this.header.filter((headerValue) => {
          return headerValue.value == value.field;
        });

        value.title = filterValue[0].title;
        value.operator = value.operator || "=";
        value.conector = value.conector || "AND";

        if (DATES.includes(value.field)) {
          if (value.operator !== "BETWEEN") {
            let date = DateTime.fromFormat(value.value, "yyyy/MM/dd HH:mm:ss", {
              zone: "America/Phoenix",
            });

            let local = date.setZone("local", { includeZone: false });
            value["parseDate1"] = value.value;

            value.value = local.toFormat("yyyy/LL/dd");
          } else {
            let parseFromDate = DateTime.fromFormat(
              value.parsefromDate,
              "yyyy/MM/dd HH:mm:ss",
              { zone: "America/Phoenix" },
            );

            let parseToDate = DateTime.fromFormat(
              value.parsetoDate,
              "yyyy/MM/dd HH:mm:ss",
              { zone: "America/Phoenix" },
            );

            value["fromDate"] = parseFromDate
              .setZone("local", {
                includeZone: false,
              })
              .toFormat("yyyy/LL/dd");

            value["toDate"] = parseToDate
              .setZone("local", {
                includeZone: false,
              })
              .toFormat("yyyy/LL/dd");

            value["parsefromDate"] = value.parsefromDate;
            value["parsetoDate"] = value.parsetoDate;
          }
        }
        return Object.assign({}, value);
      });
      console.log(filter);
      let { id, name } = filter;
      this.filterInfo = { id, name };
      this.setInventoryFilteredColumnFlag(true);
      this.updateDataTable({ filters: this.filters });
    },
    ///*******************************************//
    removeFilter(index) {
      this.filters.splice(index, 1);
      if (!this.filters.length) {
        this.filterInfo = {};
        this.setInventoryFilteredColumnFlag(false);
      }
      this.updateDataTable({ filters: this.filters });
    },
    updateFilterInfo(name) {
      this.filterInfo = {
        ...this.filterInfo,
        name,
      };
    },
    ///*******************************************//

    ////////////////////////////////////////////////////////////////////

    keyUp(event) {
      if (event.key === "Escape") {
        this.cancel();
      }
    },
    ///*******************************************//
    refreshDepots() {
      this.addMissingACL();
      this.cancel();
      this.updateDataTable({ filters: this.filters });
    },
  },
};
</script>

<style scoped>
.table-row {
  position: absolute;
  height: 80%;
  /* bottom: 0; */
}
.h-60 {
  height: 60vh;
}
.container-table {
  height: 60%;
}
/* .actions-panels {
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 25vh;
} */
.overflow-x-auto {
  overflow-x: auto;
}
.w-95 {
  /* max-width: 97%; */
  margin-left: -0.8em;
}
</style>
