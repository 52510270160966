<template>
  <ValidationObserver v-slot="{ invalid }" tag="div" style="height: 100%; width: 100%">
    <v-row style="height: 100%; width: 100%">
      <v-col cols="12" class="fill-height">
        <v-row>
          <NewOrderActions
            auctionFlag="true"
            @createNewAuction="createNewAuction"
            @saveAsDraft="saveAsDraft"
            @loadDraft="loadDraft"
            @deleteDraft="deleteSavedAuction"
            @loadLots="flagLoadLots = true"
            :invalid="invalid"
            :flagModalUnreCust="flagModalUnreCust"
            :tableSelected="tableSelected"
          />
        </v-row>
        <v-row>
          <v-col cols="6" style="max-height: calc(100vh - 150px)" class="overflow-y-auto">
            <v-row>
              <v-col class="overflow-y-auto">
                <AuctionGeneralData
                  :obj="objGeneralData"
                  :distributionList="distributionList"
                  :flagModalUnreCust="flagModalUnreCust"
                  @changeFlagModalUnreCust="changeFlagModalUnreCust"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="overflow-y-auto" style="min-height: 350px">
                <AuctionTable
                  :selectedProp="tableSelected"
                  @updateSelected="updateSelected"
                  @updateSelectedAll="updateSelectedAll"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <CreateAuctionForm
              :tableSelected="tableSelected"
              :objAuctionForm="objAuctionForm"
              :containersNo="containersNo"
              @updateSelected="updateSelected"
              @addNewLot="addNewLot"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ModalPreviewAuction
      v-if="showModalPreviewAuction"
      :show="showModalPreviewAuction"
      @changeFlagModalPreviewAuction="changeFlagModalPreviewAuction"
      @createAuction="createAuctionPreviewer"
      title="Details"
      :objPreview="objPreview"
    />
    <ModalLoadLots
      title="Load Lots"
      v-if="flagLoadLots"
      :show="flagLoadLots"
      @loadLots="loadLots"
      @changeFlagLoadLots="flagLoadLots = false"
    />
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import NewOrderActions from "@/components/OM/AllOrders/NewOrderActions.vue";
import CreateAuctionForm from "@/components/InventoryQtySumm/CreateAuctionForm.vue";
import AuctionTable from "@/components/InventoryQtySumm/AuctionTable.vue";
import AuctionGeneralData from "@/components/InventoryQtySumm/AuctionGeneralData.vue";
import ModalPreviewAuction from "@/components/Auctions/ModalPreviewAuction.vue";
import { FromFormatToTimeStamp } from "@/helpers/mocks/dates.js";
import { available_container_no } from "@/helpers/api/Orders/index.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ModalLoadLots from "@/components/Auctions/ModalLoadLots.vue";
export default {
  components: {
    NewOrderActions,
    CreateAuctionForm,
    AuctionTable,
    AuctionGeneralData,
    ValidationObserver,
    ModalPreviewAuction,
    ModalLoadLots,
  },
  async created() {
    if (this.batchInfo) {
      let item = this.batchInfo;
      this.tableSelected.push(item);
      this.$set(this.objAuctionForm, item.group, [{}]);
      let res = await this.getContainersNoAvailable(item);

      // let containers = res[0].data.map((x) => x.EquipmentID);
      this.$set(this.containersNo, item.group, res.data);
    }
    await this.getList();
  },
  computed: {
    ...mapState({
      batchInfo: (state) => state.moduleAuctions.batchInfo,
      depotManage: (state) => state.depotManage,
      distributionList: (state) => state.moduleCustomerDistribution.lists,
      savedAuction: (state) => state.moduleAuctions.savedAuction,
    }),
    ...mapGetters({
      getInventoryQtySummsAvailables: "moduleQtySumm/getInventoryQtySummsAvailables",
      getGoodRefeersWithGroup: "moduleQtySumm/getGoodRefeersWithGroup",
      getBadRefeersWithGroup: "moduleQtySumm/getBadRefeersWithGroup",
    }),
  },
  data() {
    return {
      missingFromACL: [],
      showModalPreviewAuction: false,
      objPreview: null,
      objGeneralData: {
        customers: [],
        emailList: [],
        end_day: "",
        start_day: "",
        name: "",
        time_start_day: "",
        time_end_day: "",
        menus_time_tart_day: false,
        menu_time_end_day: false,
      },
      tableSelected: [],

      objAuctionForm: {},
      containersNo: {},
      flagModalUnreCust: false,
      flagLoadLots: false,
    };
  },

  methods: {
    ...mapActions({
      createAuction: "moduleAuctions/createAuction",
      getAuctionInfo: "moduleAuctions/getAuctionInfo",
      getContainersNoAvailable: "moduleAuctions/getContainersNoAvailable",
      postSavedAuction: "moduleAuctions/postSavedAuction",
      getAllManageDepot: "getAllManageDepot",
      getList: "moduleCustomerDistribution/getList",
      getSavedAuction: "moduleAuctions/getSavedAuction",
      deleteSavedAuction: "moduleAuctions/deleteSavedAuction",
    }),
    ...mapMutations({
      setAuctionFormFlagStore: "moduleQtySumm/setAuctionFormFlag",
    }),
    async loadLots(json) {
      // this.tableSelected

      const data = JSON.parse(json);
      let groups = [
        ...new Set(data.map((x) => `${x.Location}${x.Size}${x.Type}${x.Condition}`)),
      ];

      // data.forEach((x) => {
      //   console.log(x);
      //   a = [
      //     ...a,
      this.tableSelected = this.getInventoryQtySummsAvailables.filter((value) => {
        console.log(value.group);
        return groups.includes(value.group);
      });

      let obj = {};

      data.map((x) => {
        let group = `${x.Location}${x.Size}${x.Type}${x.Condition}`;
        if (obj[group]) {
          obj[group].push({
            min_price: x.minPrice,
            name: x.name,
            comments: x.comments,
            quantity: x.qty,
            container_no: [],
          });
        } else {
          obj[group] = [
            {
              min_price: x.minPrice,
              name: x.name,
              comments: x.comments,
              quantity: x.qty,
              container_no: [],
            },
          ];
        }
        return obj;
      });

      this.objAuctionForm = obj;
      let res = await Promise.all(
        this.tableSelected.map((x) => this.getContainersNoAvailable(x))
      );

      this.tableSelected.forEach((x, index) => {
        this.$set(this.containersNo, x.group, res[index].data);
      });
      console.log(res);
      // let containers = res[0].data.map((x) => x.EquipmentID);
      // this.$set(this.containersNo, item.group, res.data);)
      // });

      // [{"qty":1,"Size":"20","Type":"ST","Cndtn":"AS IS","Depot":"USCHIDCCI","group":"USCHIDCCI20STASIS","itemID":32,"DepotId":317,"CityName":"Chicago","QtyBooked":13,"bookedQty":13,"OverBooked":0,"QtyFlagged":12,"QtyInbound":1185,"QtyReserved":9,"StateAlpha2":"Illinois","release_ref":"SAL0731230003","CountryAlhpa2":"US","auctionItemID":61,"QtyOnHandTotal":83,"winnerEmailSent":"N","InboundStatusCode":"IRI","QtyOnHandAvailable":57,"QtyOnHandTotalSell":81,"QtyOnHandTotalSold":2,"QtyAuctionReservedAll":2,"QtyAuctionReservedActive":0,"QtyAuctionReservedExpired":"2","QtyUnbidded_To_Go_To_OnHandAvailable":"15"}]
    },
    addNewLot({ group }) {
      this.lots.push({ group });
    },
    changeFlagModalUnreCust(flag) {
      this.flagModalUnreCust = flag;
    },
    async updateSelectedAll({ items, value }) {
      if (value) {
        let itemsFiltered = items.filter(
          (x) => !this.containersNo.hasOwnProperty(x.group)
        );
        let res = await Promise.all(
          itemsFiltered.map((x) =>
            available_container_no({
              status: 1,
              depot: x.Depot,
              size: x.Size,
              cndtn: x.Cndtn,
              type: x.Type,
            })
          )
        );

        this.tableSelected = [...items];

        itemsFiltered.map((x, i) => {
          this.$set(this.objAuctionForm, x.group, [{}]);
          this.$set(this.containersNo, x.group, res[i].data);
        });
      } else {
        items.map((item) => {
          delete this.objAuctionForm[item.group];

          this.tableSelected = [];

          delete this.containersNo[item.group];
        });
      }
    },
    async updateSelected({ item, value }) {
      if (value) {
        this.tableSelected.push(item);
        this.$set(this.objAuctionForm, item.group, [{}]);

        if (!this.containersNo.hasOwnProperty(item.group)) {
          let res = await available_container_no({
            status: 1,
            depot: item.Depot,
            size: item.Size,
            cndtn: item.Cndtn,
            type: item.Type,
          });

          // let containers = res.data.map((x) => {return {container: x.EquipmentID);
          this.$set(this.containersNo, item.group, res.data);
        }
      } else {
        delete this.objAuctionForm[item.group];

        this.tableSelected = this.tableSelected.filter((x) => x.group !== item.group);

        delete this.containersNo[item.group];
      }
    },
    changeFlagModalPreviewAuction(flag) {
      // console.log()/
      this.showModalPreviewAuction = flag;
    },
    async createAuctionPreviewer() {
      let res = await this.createAuction(this.objPreview);
      if (res) {
        this.getAuctionInfo({ id: res.id });
        this.$router.push("/auctions");
      }
      this.setAuctionFormFlagStore(false);
      this.objPreview = null;
    },
    async createNewAuction() {
      let obj = {};

      obj.bypass = this.objGeneralData.bypass;

      console.log("obj.bypass", obj.bypass);

      // return;

      obj.comments = this.objGeneralData.comments;
      obj.StartDateTime = FromFormatToTimeStamp(
        `${this.objGeneralData.start_day} ${this.objGeneralData.time_start_day}`,
        "yyyy-LL-dd HH:mm"
      );
      obj.EndDateTime = FromFormatToTimeStamp(
        `${this.objGeneralData.end_day} ${this.objGeneralData.time_end_day}`,
        "yyyy-LL-dd HH:mm"
      );
      obj.name = this.objGeneralData.name;
      obj.customers = [];

      this.objGeneralData.customers.map((x) => {
        if (x.primary_email) {
          let emails = x.primary_email.split(";");
          obj.customers = [
            ...obj.customers,
            {
              customerEntityId: x.organization_id,
              primary_email: emails[0],
            },
          ];
        }
      });

      obj.customers = [...obj.customers, ...this.objGeneralData.emailList];

      obj.lineItems = [];
      await this.getAllManageDepot();

      let self = this;

      this.tableSelected.map((selected) => {
        self.missingFromACL = [];

        let depotName = this.depotManage.filter((x) => selected.DepotId === x.DepotId);

        if (depotName.length == 0) {
          // console.log("selected", selected);
          // console.log("depotManage", this.depotManage);
          // console.log("depotManage", JSON.stringify(this.depotManage));

          self.missingFromACL.push(selected.Depot);
        }

        if (depotName.length > 0) {
          this.objAuctionForm[selected.group].map((item) => {
            console.log("selected ", selected);
            obj.lineItems.push({
              equipmentSize: selected.Size,
              equipmentType: selected.Type,
              equipmentCondition: selected.Cndtn,
              depot: selected.Depot,
              country: selected.CountryAlhpa2,
              state: selected.StateAlpha2,
              city: selected.CityName,
              depotId: selected.DepotId,
              name: item.name,
              minPrice: Number(item.min_price),
              comments: item.comments,
              qty: Number(item.quantity),
              ContainerNos: item.container_no,
              depotName: depotName[0].DepotName,
              access_requirements: depotName[0].access_requirements,
            });
          });
        }
      });

      console.log("missingFromACL", self.missingFromACL);

      if (self.missingFromACL.length > 0) {
        prompt(
          "Warning! These depots are missing ACL",
          JSON.stringify(self.missingFromACL)
        );
      }

      this.objPreview = obj;
      this.showModalPreviewAuction = true;
    },
    async saveAsDraft() {
      let obj = {};
      obj.objGeneralData = this.objGeneralData;
      obj.tableSelected = this.tableSelected;
      obj.objAuctionForm = this.objAuctionForm;
      obj.containersNo = this.containersNo;
      this.postSavedAuction(obj);
    },
    async loadDraft() {
      await this.getSavedAuction();
      this.objGeneralData = this.savedAuction.objGeneralData;
      this.tableSelected = this.savedAuction.tableSelected;
      this.objAuctionForm = this.savedAuction.objAuctionForm;
      this.containersNo = this.savedAuction.containersNo;
    },
  },
};
</script>

<style></style>
