<template>
  <Details :details="details" />
</template>

<script>
import Panel from "@/components/OM/Panel";
import Details from "@/components/OM/Details";
import Tabs from "@/components/OM/Tabs.vue";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    Panel,
    Tabs,
    Details,
  },
  async created() {
    if (!this.docNo) {
      await Promise.all([this.getsAllOrders(), this.getAllOrdersDetail()]);
      let docNo = this.$route.params.docNo;

      let order = await this.getOrder({ docNo });
      this.setDetailsStore(order[0]);
    }

    this.details = await this.getOrderDetail({
      docNo: this.docNo,
    });

    // if (!this.nereus_no) {
    //   await Promise.all([this.getsAllOrders(), this.getAllOrdersDetail()]);
    //   let docNo = this.$route.params.nereus_no;

    //   let order = await this.getOrderNerNo({ nereus_no });
    //   this.setDetailsStore(order[0]);
    // }

    // this.details = await this.getOrderDetailByNerNo({
    //   docNo: this.docNo,
    //   nereus_no: this.nereus_no,
    // });
  },
  computed: {
    ...mapState({
      tabSelected: (state) => state.moduleOrders.tabSelected,
      allOrders: (state) => state.moduleOrders.allOrders,
    }),
    ...mapGetters({
      docNo: "moduleOrders/docNo",
    }),
  },
  data() {
    return {
      details: [],
    };
  },
  methods: {
    ...mapMutations({
      setDetailsStore: "moduleOrders/setDetails",
    }),
    ...mapActions({
      getOrder: "moduleOrders/getOrder",
      getsAllOrders: "moduleOrders/getsAllOrders",
      getAllOrdersDetail: "moduleOrders/getAllOrdersDetail",
      getOrderDetail: "moduleOrders/getOrderDetail",
    }),
  },
};
</script>

<style></style>
