<template>
  <v-container class="ma-auto dark-blue ma-1 pa-5">
    <inventoryAllocation-modal
      :show="show"
      :itemData="itemData"
      :title="modalTitle"
      :paperRows="paperRows"
      @showModal="showModal"
      @saveInventoryAllocation="save"
    ></inventoryAllocation-modal>
    <v-row class="pa-5 flex-column">
      <v-col>
        <panel-action
          @addInventoryAllocation="addInventoryAllocation"
          @changeSearchValue="changeSearchValue"
        ></panel-action>
      </v-col>
      <v-col>
        <inventoryAllocation-table
          @showModal="showModal"
          :inventoryAllocations="filteredStatuses"
        ></inventoryAllocation-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InventoryAllocationTable from "@/components/InventoryAllocation/Table.vue";
import PanelAction from "@/components/InventoryAllocation/PanelAction.vue";
import InventoryAllocationModal from "@/components/InventoryAllocation/InventoryAllocationModal.vue";
import {
  get_inventory_allocations,
  create_inventory_allocation,
  update_toggle_inventory_allocation,
  get_countries,
} from "@/helpers/api/indexv2.js";
import { inventoryAllocationFormDataRows } from "@/helpers/mocks/inventoryallocation.js";
import { mapState, mapMutations, mapActions } from "vuex";
import { DateTime } from "luxon";
import { inventoryAllocationData } from "@/helpers/mocks/inventoryallocation.js";

export default {
  name: "InventoryAllocation",
  components: { InventoryAllocationTable, PanelAction, InventoryAllocationModal },
  async mounted() {
    /*
    this.$store.commit("setSpinnerShow", true);
    // let orgs = buyerData();
    // console.log(orgs);
    // this.$store.commit("setOrganizations", orgs);
    try {
      let containerstatuses = await get_container_statuses();
      console.log('result: ', containerstatuses.data);
      this.$store.commit("setContainerStatuses", containerstatuses.data);
      console.log('view index state containerStatuses: ', this.$store.state.containerStatuses)
    } catch (error) {
      console.log(error);
    }

    this.$store.commit("setSpinnerShow", false);
    */
  },
  data() {
    return {
      show: false,
      modalTitle: "",
      itemData: {},
      searchValue: "",
      paperRows: [],
      itemsCountries: [],
      itemsCountriesTmp: [],
    };
  },
  computed: {
    ...mapState(["inventoryAllocations", "countries"]),

    filteredStatuses() {
      const dates = ["DateCreated", "DateUpdated"];
      if (this.inventoryAllocations) {
        const filtData = [
          ...this.inventoryAllocations.filter((stat, index) => {
            console.log("stat", stat);
            if (
              stat.Country.toLowerCase().startsWith(
                this.searchValue.toLowerCase(),
              ) ||
              stat.State
                .toLowerCase()
                .startsWith(this.searchValue.toLowerCase()) ||
              stat.City
                .toLowerCase()
                .startsWith(this.searchValue.toLowerCase()) ||
              stat.Depot.toLowerCase().startsWith(this.searchValue.toLowerCase()) ||
              stat.Size
                .toLowerCase()
                .startsWith(this.searchValue.toLowerCase()) ||
              stat.Type
                .toLowerCase()
                .startsWith(this.searchValue.toLowerCase()) ||
              stat.Cndtn
                .toLowerCase()
                .startsWith(this.searchValue.toLowerCase())
            ) {
              return stat;
            }
          }),
        ];

        return filtData;
      }
    },
  },
  methods: {
    ...mapMutations(["setSnackbar", "setSpinnerShow"]),
    ...mapActions(["saveInventoryAllocation"]),
    async populateCountries() {
        itemsCountriesTmp: [
          { name: 'United States of America', iso2: 'US' },
          { name: 'Canada', iso2: 'CA' },
          { name: 'Malaysia', iso2: 'MY' },          
        ]
        this.itemsCountries = itemsCountriesTmp ;
    },
    showModal(data) {
      if (data) {
        let { item, paperRow, title } = data;

        if (item) this.itemData = Object.assign({}, item);
        if (!this.show) {
          this.paperRows = paperRow
            ? [inventoryAllocationFormDataRows[paperRow]]
            : inventoryAllocationFormDataRows;
          this.modalTitle = title;
        }
        this.populateCountries();

      }
      this.show = !this.show;
    },
    addInventoryAllocation() {
      this.populateCountries();
      this.itemData = {
        Country: "",
        State: "",
        City: "",
        Depot: "",
        Size: "",
        Type: "",
        Cndtn: "",
        PartnerID: 0,
        QtyAllocated: 0,
        ExpiryPeriod: 99,
        ExpiryTimeType: "Years"        
      };

      this.paperRows = inventoryAllocationFormDataRows;
      this.modalTitle = "New Inventory Allocation";
      this.show = true;
    },
    async save(flagNew) {
      await this.saveInventoryAllocation({ flagNew, data: this.itemData });

      this.show = false;
    },
    changeSearchValue(searchValue) {
      this.searchValue = searchValue;
    },
  },
};
</script>

<style></style>
