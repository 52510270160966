<template>
  <v-container class="ma-0 text-end fill-height pa-0" fluid>
    <v-row class="fill-height">
      <v-col cols="12" class="fill-height pa-0 ma-0">
        <v-row class="ma-0 pa-3 justify-space-between flex-row gradient">
          <v-col cols="2" class="ma-0 pa-1 rounded">
            <Card :title="'Total Containers'">
              <template v-slot:content>
                <v-row>
                  <v-col cols="12">
                    <body-card data-cy="total-containers" :value="totalContainer" />
                    <!-- <v-icon color="#fff" size="3vw">
                      mdi-chart-areaspline
                    </v-icon> -->
                    <!-- {{ totalContainer }} -->
                  </v-col>
                </v-row>
              </template>
            </Card>
          </v-col>
          <v-col cols="5" class="ma-0 pa-1 rounded">
            <Card :title="'Sales Revenue'">
              <template v-slot:content>
                <v-row>
                  <v-col cols="6">
                    <body-card
                      data-cy="sr-current-month"
                      :title="'Current month'"
                      :value="
                        revenueInCurrentMonth
                          ? (Math.floor(revenueInCurrentMonth * 100) / 100).toFixed(2)
                          : 0
                      "
                    >
                    </body-card>
                  </v-col>
                  <v-col cols="6">
                    <body-card
                      data-cy="sr-last-thirty-days"
                      :title="'Last thirty days'"
                      :value="
                        revenueInLastThirtyDays
                          ? (Math.floor(revenueInLastThirtyDays * 100) / 100).toFixed(2)
                          : 0
                      "
                    />
                  </v-col>
                </v-row>
              </template>
            </Card>
          </v-col>
          <v-col cols="5" class="ma-0 pa-1 rounded">
            <Card :title="'Units Sold'">
              <template v-slot:content>
                <v-row>
                  <v-col cols="6">
                    <body-card
                      data-cy="us-current-month"
                      :title="'Current month'"
                      :value="unitsSoldThisMonth"
                    />
                  </v-col>
                  <v-col cols="6">
                    <body-card
                      data-cy="us-last-thirty-days"
                      :title="'Last thirty days'"
                      :value="soldUnitsLastThirtyDays"
                    />
                  </v-col>
                </v-row>
              </template>
            </Card>
          </v-col>
        </v-row>
        <v-row class="ma-0 mt-2 pa-0 justify-space-between flex-row h-50">
          <v-col cols="4" class="fill-height pa-1 col-sizes">
            <div
              class="pa-1 d-flex fill-height elevation-5 flex-column justify-center align-center dark-blue rounded"
            >
              <v-subheader class="align-self-start pa-5" v-if="lineChartLoaded">{{
                `Containers by age - Total= ${lineData[3]}`
              }}</v-subheader>

              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                v-if="!lineChartLoaded"
              ></v-progress-circular>
              <LineChart
                data-cy="line-containers-by-age"
                css-classes=" chart"
                :data="lineData"
                v-if="lineChartLoaded"
              >
              </LineChart>
            </div>
          </v-col>

          <v-col cols="8" class="fill-height col-sizes pa-1">
            <div
              class="pa-1 d-flex fill-height elevation-5 flex-column justify-center align-center dark-blue rounded"
            >
              <v-subheader class="align-self-start ma-5" v-if="barLoaded"
                >Containers by type</v-subheader
              >

              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                v-if="!barLoaded"
              ></v-progress-circular>

              <Bar
                data-cy="bar-equipment-by-type"
                css-classes="chart"
                :data="equipmentCountByType"
                v-if="barLoaded"
              />
            </div>
          </v-col>
          <!-- <v-col cols="4" class=" fill-height col-sizes pa-1">
            <div
              class=" pa-5 d-flex fill-height elevation-5 flex-column justify-center align-start dark-blue rounded"
            >
              <v-subheader class="text-start pa-1"
                >OFFHIRE SELLS LAST 5 WEEKS</v-subheader
              >
              <MenuPieChart />
              <Pie css-classes="chart" />
            </div>
          </v-col> -->
        </v-row>
      </v-col>

      <!-- <v-col cols="6" class="fill-height pa-0 ma-0 pl-5 pr-5">
        <FiltersMenu />
        <Map />
      </v-col> -->
      <v-col style="display: none" cols="3" class="fill-height pa-0 ma-0 pl-5 pr-5">
        <v-row class="fill-height ma-0 align-content-start">
          <div class="newfeedsPanel elevation-5">
            <!-- <v-subheader class=" pa-1  ">NEWFEEDS</v-subheader>
            <v-col v-for="(newfeed, index) of newfeeds" :key="index">
              <Newfeed :data="newfeed" />
            </v-col> -->
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FiltersMenu from "@/components/Insights/FiltersMenu.vue";
import Map from "@/components/Insights/Map.vue";
import Card from "@/components/Insights/Card.vue";
import Bar from "@/components/Insights/Bar.vue";
import Pie from "@/components/Insights/Pie.vue";
import LineChart from "@/components/Insights/LineChart.vue";
import Newfeed from "@/components/Insights/Newfeed.vue";
import MenuPieChart from "@/components/Insights/MenuPieChart.vue";
import BodyCard from "@/components/Insights/BodyCard.vue";
import {
  bands,
  sold_thirty_days,
  sold_current_month,
  revenue_current_month,
  revenue_thirty_days,
  not_sold_items,
  equipment_count_by_type,
} from "@/helpers/api/indexv2.js";

export default {
  components: {
    FiltersMenu,
    Map,
    Card,
    Bar,
    Pie,
    Newfeed,
    MenuPieChart,
    LineChart,
    BodyCard,
  },
  async mounted() {
    const self = this;

    this.interval = setInterval(() => {
      if (this.value === 100) {
        setTimeout(function () {
          return (this.value = 0);
        }, 2000);
      }
      this.value += 15;
    }, 300);

    this.lineChartLoaded = false;
    this.$store.commit("setSpinnerShow", false);
    try {
      try {
        // let unitsSoldThisMonth = await sold_current_month();
        // this.unitsSoldThisMonth = unitsSoldThisMonth.data[0].unitsSoldThisMonth;

        sold_current_month().then(
          (unitsSoldThisMonth) =>
            (self.unitsSoldThisMonth = unitsSoldThisMonth.data[0].unitsSoldThisMonth)
        );

        // let soldUnitsLastThirtyDays = await sold_thirty_days();
        // this.soldUnitsLastThirtyDays = soldUnitsLastThirtyDays.data[0].soldUnitsLastThirtyDays;

        sold_thirty_days().then((soldUnitsLastThirtyDays) => {
          self.soldUnitsLastThirtyDays =
            soldUnitsLastThirtyDays.data[0].soldUnitsLastThirtyDays;
        });
      } catch (errUnitsSold) {
        console.log("errUnitsSold", errUnitsSold);
      }

      try {
        // let revenueInCurrentMonth = await revenue_current_month();
        // this.revenueInCurrentMonth =
        //   revenueInCurrentMonth.data[0].revenueInCurrentMonth;

        revenue_current_month().then(
          (revenueInCurrentMonth) =>
            (self.revenueInCurrentMonth =
              revenueInCurrentMonth.data[0].revenueInCurrentMonth)
        );

        // let revenueInLastThirtyDays = await revenue_thirty_days();
        // this.revenueInLastThirtyDays =
        //   revenueInLastThirtyDays.data[0].revenueInLastThirtyDays;

        revenue_thirty_days().then(
          (revenueInLastThirtyDays) =>
            (self.revenueInLastThirtyDays =
              revenueInLastThirtyDays.data[0].revenueInLastThirtyDays)
        );
      } catch (errRevenue) {
        console.log("errRevenue", errRevenue);
      }

      try {
        let notSoldItems = await not_sold_items();
        this.totalContainer = notSoldItems.data[0].inventoryCount;
      } catch (errNotSoldTotal) {
        console.log("errNotSoldTotal", errNotSoldTotal);
      }

      try {
        // let { data } = await bands();
        // this.lineData = Object.values(data);

        bands().then((data) => {
          self.lineData = Object.values(data.data);
          self.lineChartLoaded = true;
        });
      } catch (errBands) {
        console.log("errBands", errBands);
      }

      // this.lineChartLoaded = true;
      try {
        // let equipmentCountByType = await equipment_count_by_type();
        // console.log(equipmentCountByType);
        // this.equipmentCountByType = equipmentCountByType.data;

        equipment_count_by_type().then((equipmentCountByType) => {
          self.equipmentCountByType = equipmentCountByType.data;
          this.barLoaded = true;
        });
      } catch (error) {
        console.log("errCountByType", error);
      }

      // this.barLoaded = true;
    } catch (error) {
      console.log("dashboard1 mount error", error);
    }
    this.$store.commit("setSpinnerShow", false);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  data() {
    return {
      newfeeds: [
        { dateTime: "dateTime", message: "A cool and very important message" },
        { dateTime: "dateTime", message: "A cool and very important message" },
        { dateTime: "dateTime", message: "A cool and very important message" },
        { dateTime: "dateTime", message: "A cool and very important message" },
        { dateTime: "dateTime", message: "A cool and very important message" },
        { dateTime: "dateTime", message: "A cool and very important message" },
        { dateTime: "dateTime", message: "A cool and very important message" },
      ],
      lineData: [],
      lineChartLoaded: false,
      barLoaded: false,
      totalContainer: 0,
      revenueInCurrentMonth: "",
      revenueInLastThirtyDays: "",
      soldUnitsLastThirtyDays: "",
      unitsSoldThisMonth: "",
      equipmentCountByType: "",
      interval: {},
      value: 0,
    };
  },
};
</script>

<style scoped>
.gradient {
  background: #262737;
  background: radial-gradient(
    circle,
    rgba(38, 39, 55, 1) 62%,
    rgba(38, 39, 55, 1) 68%,
    rgba(55, 81, 120, 1) 100%,
    rgba(50, 132, 244, 1) 100%
  );
}
.h-50 {
  height: 50%;
}

.h-60 {
  height: 60%;
}
.line-chart {
  background-color: #262737;
}
.col-sizes {
  flex: none;
}

.chart {
  width: 100%;
  height: 80%;
}
.newfeedsPanel {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.title-color {
  color: #3284f4 !important;
  font-weight: bold;
}

.h-10 {
  height: 10%;
}
</style>
