<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="10" class="white"> <Form :list="list" :loading="loading" /> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Form from "@/components/CustomerDistribution/Form.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Form,
  },
  async created() {
    await this.getsOrganizations();
    let { id } = this.$route.params;
    this.loading = true;

    if (id) {
      this.list = this.lists.find((x) => x.id == id);
    }
    this.loading = false;
  },
  data() {
    return {
      list: null,
      loading: false,
    };
  },
  watch: {
    lists: {
      handler() {
        let { id } = this.$route.params;
        this.loading = true;

        if (id) {
          this.list = this.lists.find((x) => x.id == id);
        }
        this.loading = false;
      },
    },
    immediate: true,
  },
  computed: {
    ...mapState({
      lists: (state) => state.moduleCustomerDistribution.lists,
    }),
  },
  methods: {
    ...mapActions({
      getsOrganizations: "moduleOrganizations/getsOrganizations",
    }),
  },
};
</script>

<style></style>
