<template>
  <v-container>
    <v-tabs
      v-model="tab"
      class="pa-0 ma-0"
      background-color="dark_blue"
      left
      leave-absolute
    >
      <v-tab v-for="tabValue in tabValues" :key="tabValue.title" class="tab">
        {{ tabValue.title }}
      </v-tab>
    </v-tabs>
    <!-- <v-tabs-items v-model="tab"> -->
    <!-- v-for="tabValue in tabValues" :key="tabValue.title" -->
    <!-- <v-tab-item :transition="false"> -->
    <v-row class="fill-width">
      <v-col cols="12">
        <component :is="tabValues[tab].component" />
      </v-col>
    </v-row>

    <!-- {{ tabValues[tab].component }} -->
    <!-- </v-tab-item> -->
    <!-- </v-tabs-items> -->
  </v-container>
</template>

<script>
import DepotManage from "@/views/Depots/DepotManage/index.vue";
import DepotTermination from "@/views/Depots/DepotTermination/index.vue";
import DepotAllSale from "@/views/Depots/DepotAllSale/index.vue";
export default {
  name: "Depots",
  components: {
    DepotManage,
    DepotTermination,
    DepotAllSale,
  },
  async mounted() {},
  data() {
    return {
      show: false,
      tab: 0,
    };
  },
  computed: {
    tabValues() {
      return [
        {
          title: "Depots",
          component: DepotManage,
        },
        { title: "Depot Terminations", component: DepotTermination },
        // { title: "ALL SAle hold", component: DepotAllSale },
      ];
    },
  },

  methods: {},
};
</script>

<style></style>
