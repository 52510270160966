<template>
  <v-container class="ma-auto dark-blue ma-1 pa-5">
    <containerStatus-modal
      :show="show"
      :itemData="itemData"
      :title="modalTitle"
      :paperRows="paperRows"
      @showModal="showModal"
      @saveContainerStatus="save"
    ></containerStatus-modal>
    <v-row class="pa-5 ma-0 flex-column panel-row">
      <v-col class="ma-0 pa-0">
        <panel-action
          @addContainerStatus="addContainerStatus"
          @changeSearchValue="changeSearchValue"
        ></panel-action>
      </v-col>
      <v-col>
        <containerStatus-table
          @showModal="showModal"
          :containerStatuses="filteredStatuses"
        ></containerStatus-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContainerStatusTable from "@/components/ContainerStatus/Table.vue";
import PanelAction from "@/components/ContainerStatus/PanelAction.vue";
import ContainerStatusModal from "@/components/ContainerStatus/ContainerStatusModal.vue";
import {
  get_container_statuses,
  create_container_status,
  update_toggle_container_status,
} from "@/helpers/api/indexv2.js";
import { containerStatusFormDataRows } from "@/helpers/mocks/containerstatus.js";
import { mapState, mapMutations, mapActions } from "vuex";
import { DateTime } from "luxon";
import { containerStatusData } from "@/helpers/mocks/containerstatus.js";

export default {
  name: "ContainerStatus",
  components: { ContainerStatusTable, PanelAction, ContainerStatusModal },
  async mounted() {
    /*
    this.$store.commit("setSpinnerShow", true);
    // let orgs = buyerData();
    // console.log(orgs);
    // this.$store.commit("setOrganizations", orgs);
    try {
      let containerstatuses = await get_container_statuses();
      console.log('result: ', containerstatuses.data);
      this.$store.commit("setContainerStatuses", containerstatuses.data);
      console.log('view index state containerStatuses: ', this.$store.state.containerStatuses)
    } catch (error) {
      console.log(error);
    }

    this.$store.commit("setSpinnerShow", false);
    */
  },
  data() {
    return {
      show: false,
      modalTitle: "",
      itemData: {},
      searchValue: "",
      paperRows: [],
    };
  },
  computed: {
    ...mapState(["containerStatuses"]),

    filteredStatuses() {
      const dates = ["DateCreated", "DateUpdated"];
      if (this.containerStatuses.length) {
        const filtData = [
          ...this.containerStatuses.filter((stat, index) => {
            if (
              stat.Code.toLowerCase().startsWith(this.searchValue.toLowerCase()) ||
              stat.step_descr.toLowerCase().startsWith(this.searchValue.toLowerCase()) ||
              stat.substep_descr
                .toLowerCase()
                .startsWith(this.searchValue.toLowerCase()) ||
              stat.Mode.toLowerCase().startsWith(this.searchValue.toLowerCase())
            ) {
              dates.forEach((date) => {
                if (stat[date])
                  stat[date] =
                    DateTime.fromSQL(stat.DateCreated).invalid === null
                      ? DateTime.fromSQL(org.DateCreated).toFormat("LL/dd/yyyy")
                      : stat.DateCreated;
              });
              return stat;
            }
          }),
        ];

        return filtData;
      }
    },
  },
  methods: {
    ...mapMutations(["setSnackbar", "setSpinnerShow"]),
    ...mapActions(["saveContainerStatus"]),
    showModal(data) {
      if (data) {
        let { item, paperRow, title } = data;

        console.log("index showModal", data);

        if (item) this.itemData = Object.assign({}, item);
        if (!this.show) {
          this.paperRows = paperRow
            ? [containerStatusFormDataRows[paperRow]]
            : containerStatusFormDataRows;
          this.modalTitle = title;
        }
      }
      this.show = !this.show;
    },
    addContainerStatus() {
      this.itemData = {
        Code: "",
        substep_descr: "",
        Mode: "",
        Interpretation: "",
        step_id: "",
        SequenceNo: "",
        Other: "",
      };

      this.paperRows = containerStatusFormDataRows;
      this.modalTitle = "New Container Status";
      this.show = true;
    },
    async save(flagNew) {
      await this.saveContainerStatus({ flagNew, data: this.itemData });

      this.show = false;
    },
    changeSearchValue(searchValue) {
      this.searchValue = searchValue;
    },
  },
};
</script>

<style></style>
