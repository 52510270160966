<template>
  <v-container class="ma-auto dark-blue ma-1 pa-5">
    <buyer-modal
      :show="show"
      :itemData="itemData"
      :title="modalTitle"
      :paperRows="paperRows"
      @showModal="showModal"
      @saveOrg="saveOrg"
    ></buyer-modal>
    <v-row class="pa-5 flex-column panel-row dark-blue">
      <v-col>
        <panel-action
          @addOrg="addOrg"
          @changeSearchValue="changeSearchValue"
        ></panel-action>
      </v-col>
      <v-col>
        <buyer-table @showModal="showModal" :orgs="filteredOrgs"></buyer-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BuyerTable from "@/components/Buyers/Table.vue";
import PanelAction from "@/components/Buyers/PanelAction.vue";
import BuyerModal from "@/components/Buyers/BuyerModal.vue";
import {
  get_orgs,
  create_buyer_party,
  update_buyer_party,
} from "@/helpers/api/indexv2.js";
import { buyerFormDataRows } from "@/helpers/mocks/buyers.js";
import { mapState, mapMutations } from "vuex";
import { DateTime } from "luxon";
import { buyerData } from "@/helpers/mocks/buyers.js";

export default {
  name: "Buyer",
  components: { BuyerTable, PanelAction, BuyerModal },
  async mounted() {
    this.$store.commit("setSpinnerShow", true);
    // let orgs = buyerData();
    // console.log(orgs);
    // this.$store.commit("setOrganizations", orgs);
    try {
      let orgs = await get_orgs();
      console.log(orgs);
      this.$store.commit("setOrganizations", orgs.data);
    } catch (error) {
      console.log(error);
    }

    this.$store.commit("setSpinnerShow", false);
  },
  data() {
    return {
      show: false,
      modalTitle: "",
      itemData: {},
      searchValue: "",
      paperRows: [],
    };
  },
  computed: {
    ...mapState(["organizations"]),
    filteredOrgs() {
      const dates = ["DateCreated", "DateUpdated"];
      if (this.organizations.length) {
        const filtData = [
          ...this.organizations.filter((org, index) => {
            if (org.name.toLowerCase().startsWith(this.searchValue.toLowerCase())) {
              dates.forEach((date) => {
                if (org[date])
                  org[date] =
                    DateTime.fromSQL(org.DateCreated).invalid === null
                      ? DateTime.fromSQL(org.DateCreated).toFormat("LL/dd/yyyy")
                      : org.DateCreated;
              });
              return org;
            }
          }),
        ];

        return filtData;
      }
    },
  },
  methods: {
    ...mapMutations(["setSnackbar"]),
    showModal(data) {
      if (data) {
        let { item, paperRow, title } = data;

        if (item) this.itemData = Object.assign({}, item);
        if (!this.show) {
          this.paperRows = paperRow ? [buyerFormDataRows[paperRow]] : buyerFormDataRows;
          this.modalTitle = title;
        }
      }
      this.show = !this.show;
    },
    addOrg() {
      this.itemData = {
        externalName: "",
        address: "",
        contact_info_name: "",
        contact_info_title: "",
        contact_info_phone: "",
        contact_info_email: "",
      };

      this.paperRows = buyerFormDataRows;
      this.modalTitle = "New Buyer Party";
      this.show = true;
    },
    async saveOrg(newOrg) {
      let response;
      if (newOrg.new) {
        this.$store.commit("setSpinnerShow", true);
        try {
          response = await create_buyer_party({
            ...this.itemData,
          });
        } catch (error) {
          console.log(error);
        }

        this.show = false;
        if (response && response.pass) {
          try {
            let orgs = await get_orgs();
            this.$store.commit("setOrganizations", orgs.data);
            this.setSnackbar({
              text: response.msg,
              status: "success",
              show: "showNav",
            });
          } catch (error) {
            console.log(error);
          }
        } else {
          this.setSnackbar({
            text: response.msg,
            status: "danger",
            show: "showNav",
          });
        }
        this.$store.commit("setSpinnerShow", false);
      } else {
        try {
          response = await update_buyer_party({
            ...this.itemData,
          });
        } catch (error) {
          console.log(error);
        }
        this.show = false;

        if (response && response.pass) {
          this.$store.commit("setSpinnerShow", true);
          try {
            let orgs = await get_orgs();
            this.$store.commit("setOrganizations", orgs.data);
            this.setSnackbar({
              text: response.msg,
              status: "success",
              show: "showNav",
            });
          } catch (error) {
            console.log(error);
          }
          this.$store.commit("setSpinnerShow", false);
        } else {
          this.setSnackbar({
            text: response.msg,
            status: "danger",
            show: "showNav",
          });
        }
      }
    },
    changeSearchValue(searchValue) {
      this.searchValue = searchValue;
    },
  },
};
</script>

<style></style>
