<template light>
  <v-card light class="inv-warning-top" style="width: 100vw; padding: 10px">
    <v-card-title class="card-t">
      Inventory Warnings
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>

      <v-spacer></v-spacer>
      <v-btn color="primary" @click="exportTemplate('inventoryWarning')"
        >Export</v-btn
      >
    </v-card-title>
    <v-data-table
      light
      :headers="headers"
      :items="items"
      :search="search"
    ></v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import XLSXStyle from "xlsx-js-style";
import { generateTableData } from "@/utils/tables.js";

import { INVENTORY_WARNINGS } from "@/helpers/mocks/inventoryqtysumm.js";

export default {
  async created() {
    await this.getInventoryWarnings();

    console.log("listInventoryWarnings", this.listInventoryWarnings.data);

    this.items = this.listInventoryWarnings.data;

    this.tableData = this.listInventoryWarnings.data;

    this.tableDataCopy = this.tableData.map((x) => {
      return { ...x };
    });
  },
  computed: {
    ...mapState({
      listInventoryWarnings: (state) =>
        state.moduleInventoryWarnings.listInventoryWarnings,
    }),
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "id",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "container_no", value: "container_no" },
        { text: "message", value: "message" },
        { text: "response", value: "response" },
        { text: "processor_target", value: "processor_target" },
        { text: "original_field", value: "original_field" },
        { text: "original_value", value: "original_value" },
        { text: "incoming_field", value: "incoming_field" },
        { text: "incoming_value", value: "incoming_value" },
        { text: "date_created", value: "date_created" },
      ],
      items: [],
    };
  },

  methods: {
    ...mapActions({
      getInventoryWarnings: "moduleInventoryWarnings/getInventoryWarnings",
    }),

    exportTemplate(option) {
      console.log(this.tableDataCopy);
      console.log(INVENTORY_WARNINGS);

      let d = new Date();
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

      var wb = XLSXStyle.utils.book_new();
      let tableData = generateTableData(this.tableDataCopy, INVENTORY_WARNINGS);

      console.log("tableData", tableData);

      var ws = XLSXStyle.utils.json_to_sheet(tableData);

      console.log("ws", ws);

      var wscols = [];
      //A === 65 and 74 === J
      let tableStart = 65;
      let tableEnd = 74;
      for (let i = tableStart; i <= tableEnd; i++) {
        let value = String.fromCharCode(i);
        // console.log("value", value);

        // if (ws[`${value}1`].s === undefined) break;
        // ws[`${value}1`].s = {
        //   font: {
        //     bold: true,
        //   },
        //   fill: {
        //     fgColor: {
        //       rgb: "FF86c5da",
        //     },
        //   },
        //   alignment: {
        //     horizontal: "center",
        //     wrapText: false,
        //   },
        // };

        if (i == tableStart) {
          wscols.push({ wch: 15 });
        } else {
          wscols.push({ wch: 25 });
        }
      }

      ws["!cols"] = wscols;

      let filename = `inv_warn_${da}-${mo}-${ye}_${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

      XLSXStyle.utils.format_cell;
      XLSXStyle.utils.book_append_sheet(wb, ws, filename);

      /* Trigger Download with `writeFile` */
      XLSXStyle.writeFile(wb, filename + ".xlsx", { compression: true });
    },
  },
};
</script>

<style>
.inv-warning-top .card-t {
  font-size: 1.5rem !important;
}
.inv-warning-top span {
  font-size: 1.2rem !important;
}

.inv-warning-top tr td {
  font-size: 1.1rem !important;
  /* font-weight: 400; */
}
</style>
