<template>
  <TableLayout v-if="!auctionFormFlag">
    <template v-slot:panel v-if="!newOrderFlag && !auctionFormFlag">
      <panel-action
        :tableDataCopy="tableData"
        @setSearchValue="setSearchValue"
        @exportTemplate="exportTemplate('')"
        @exportTemplateDepots="exportTemplate('depots')"
        @refreshData="refreshData()"
        @dataFiltered="dataFiltered"
      ></panel-action>
    </template>
    <template v-slot:table>
      <template v-if="newOrderFlag">
        <NewOrderActions :newOrderFlag="newOrderFlag" />
        <v-row>
          <v-col cols="12">
            <NewOrder />
          </v-col>
        </v-row>
      </template>
      <!-- customerData -->
      <template v-else>
        <div class="px-4">
          <Tabs
            :tab="tab"
            :tabValues="[
              { title: 'ALL' },
              { title: 'REFFERS' },
              { title: 'GATE BUY' },
            ]"
            @changeTab="changeTab"
            :classes="`elevation-0  font-weight-bold`"
            :btnclasses="'primary lighten-2 font-weight-light white--text font-weight-bold rounded-0'"
            :classActive="'primary darken-1 white--text'"
          />
        </div>
        <div style="" class="table-panel-grid" v-if="tab === 0">
          <QTYSummTable
            @showInfoPanel="showInfoPanel($event, true)"
            :style="`min-width: ${tableColWidth}`"
            :data="tableDataCopy"
            :loadingTable="loadingTable"
          />
          <div
            class="findme2 fill-height"
            :style="`width: ${sidePanelColWidth}`"
          >
            <InfoPanel2
              v-if="sidePanelShow"
              :info="sidePanelData"
              @closePanelInfo="showInfoPanel(false)"
            />
          </div>
        </div>
        <template v-else-if="tab === 1">
          <ReffersTable />
        </template>
        <template v-else-if="tab === 2">
          <GateBuyTable />
        </template>
        <!-- <template v-else-if="tab === 3">
          <SellTableWGBRem />
        </template> -->
      </template>
    </template>
  </TableLayout>

  <CreateAuction v-else></CreateAuction>
</template>

<script>
import TableLayout from "@/layout/TableLayout.vue";
import PanelAction from "@/components/InventoryQtySumm/PanelAction.vue";
import QTYSummTable from "@/components/InventoryQtySumm/Table.vue";
import InfoPanel2 from "@/components/InventoryQtySumm/InfoPanel2.vue";
import NewOrder from "@/components/OM/AllOrders/NewOrder.vue";
import NewOrderActions from "@/components/OM/AllOrders/NewOrderActions.vue";
import CreateAuction from "@/views/Auctions/CreateAuction.vue";
import Tabs from "@/components/General/Tabs.vue";
import GateBuyTable from "@/components/InventoryQtySumm/GateBuyTable.vue";
import SellTableWGBRem from "@/components/InventoryQtySumm/SellTableWGBRem.vue";

import { mapActions, mapMutations, mapState } from "vuex";

import {
  get_inventory_qty_summs,
  get_inventory_qty_summs_bypass_cache,
  get_coname_inventoryqtysumm,
} from "@/helpers/api/indexv2.js";

import { search } from "@/utils/panelAction.js";
import {
  HEADER_QTYSUMM,
  HEADER_QTYSUMM_DEPOTS,
} from "@/helpers/mocks/inventoryqtysumm.js";
import { generateTableData } from "@/utils/tables.js";
import XLSXStyle from "xlsx-js-style";
import ReffersTable from "@/components/InventoryQtySumm/ReffersTable.vue";

export default {
  components: {
    TableLayout,
    PanelAction,
    QTYSummTable,
    InfoPanel2,
    NewOrder,
    NewOrderActions,
    CreateAuction,
    Tabs,
    ReffersTable,
    GateBuyTable,
    // SellTableWGBRem,
  },
  async created() {
    // if (this.$route.query.auction) {
    //   await this.dataForAuction();
    // } else {
    await this.refreshData();
    // }
    // this.setAuctionFormFlagStore(!!this.$route.query.auction);
  },
  computed: {
    ...mapState({
      newOrderFlag: (state) => state.moduleOrders.newOrderFlag,
      auctionFormFlag: (state) => state.moduleQtySumm.auctionFormFlag,
    }),
  },
  data() {
    return {
      sidePanelData: {},
      tableDataCopy: [],
      tableData: [],
      sidePanelShow: false,
      newOrder: false,
      tableColWidth: "100%",
      sidePanelColWidth: "100%",
      searchValue: "",
      coNames: null,
      loadingTable: false,
      tab: 0,
    };
  },
  watch: {
    // "$route.query.auction": {
    //   immediate: true,
    //   async handler() {
    //     this.setAuctionFormFlagStore(!!this.$route.query.auction);
    //     if (!this.$route.query.auction) {
    //       await this.refreshData();
    //     } else {
    //       this.addBatchInfo(null);
    //     }
    //   },
    // },

    searchValue() {
      this.tableDataCopy = this.tableData.map((x) => {
        return { ...x };
      });
      this.tableDataCopy = search({
        searchInput: this.searchValue,
        header: HEADER_QTYSUMM,
        arr: this.tableData,
      });
    },
  },
  methods: {
    ...mapMutations({
      setNewOrderFlag: "moduleOrders/setNewOrderFlag",
      setOrdersData: "moduleOrders/setOrdersData",
      setSpinnerShow: "setSpinnerShow",
      setInventoryQtySumms: "moduleQtySumm/setInventoryQtySumms",
      setCoNames: "moduleQtySumm/setCoNames",
      setAuctionFormFlagStore: "moduleQtySumm/setAuctionFormFlag",
      addBatchInfo: "moduleAuctions/addBatchInfo",
    }),
    ...mapActions({
      getOrdersTerm: "moduleOrders/getOrdersTerm",
      getOrdersType: "moduleOrders/getOrdersType",
      getCustomersDepot: "moduleQtySumm/getCustomersDepot",
      getGoodReefers: "moduleQtySumm/getGoodReefers",
      getBadReefers: "moduleQtySumm/getBadReefers",
      getsOrganizations: "moduleOrganizations/getsOrganizations",
      getSavedAuction: "moduleAuctions/getSavedAuction",
    }),
    changeTab(value) {
      this.tab = value;
    },
    dataFiltered(data) {
      this.tableDataCopy = data;
    },

    async showInfoPanel(data, flag) {
      this.sidePanelShow = flag;
      if (this.sidePanelShow) {
        await this.getCustomersDepot(data);

        this.sidePanelData = { ...data };
        this.tableColWidth = "70%";
      } else {
        this.sidePanelData = {};
        this.sheet = false;
        this.tableColWidth = "100%";
      }
    },
    async dataForAuction() {
      this.setSpinnerShow(true);

      let res = await Promise.allSettled([
        get_inventory_qty_summs(),
        this.getsOrganizations(),
        this.getSavedAuction(),
        this.getGoodReefers(),
        this.getBadReefers(),
      ]);

      this.tableData = res[0].value.data.mergedData;
      this.setInventoryQtySumms(this.tableData);
      this.tableDataCopy = this.tableData.map((x) => {
        return { ...x };
      });

      this.setSpinnerShow(false);
    },
    async refreshData() {
      this.tableData = [];
      this.setInventoryQtySumms([]);
      this.tableDataCopy = [];
      this.setNewOrderFlag(false);
      this.setOrdersData([]);
      this.loadingTable = true;
      // this.setSpinnerShow(true);

      let res = await Promise.allSettled([
        get_inventory_qty_summs_bypass_cache(),
        this.getOrdersTerm(),
        this.getOrdersType(),
        get_coname_inventoryqtysumm(),
        this.getsOrganizations(),
      ]);

      this.tableData = res[0].value.data.mergedData;
      this.setInventoryQtySumms(this.tableData);
      this.tableDataCopy = this.tableData.map((x) => {
        return { ...x };
      });

      this.coNames = res[3].value.reduce((groups, item) => {
        const group =
          groups[
            `${item.Depot.toLowerCase().trim()}_${item.Size.toLowerCase().trim()}_${item.Type.toLowerCase().trim()}_${item.Cndtn.toLowerCase().trim()}`
          ] || [];
        group.push(item);
        groups[
          `${item.Depot.toLowerCase().trim()}_${item.Size.toLowerCase().trim()}_${item.Type.toLowerCase().trim()}_${item.Cndtn.toLowerCase().trim()}`
        ] = group;
        return groups;
      }, {});

      this.setCoNames(this.coNames);
      this.sidePanelData = {};
      this.sheet = false;
      this.tableColWidth = "100%";
      // this.setSpinnerShow(false);
      this.loadingTable = false;
    },
    setSearchValue(value) {
      this.searchValue = value;
    },
    exportTemplate(option) {
      let d = new Date();
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

      var wb = XLSXStyle.utils.book_new();
      let tableData = generateTableData(
        this.tableDataCopy,
        option === "depots" ? HEADER_QTYSUMM_DEPOTS : HEADER_QTYSUMM,
      );

      var ws = XLSXStyle.utils.json_to_sheet(tableData);

      var wscols = [];
      //A === 65 and 74 === J
      let tableStart = 65;
      let tableEnd = option === "depots" ? 71 : 77;
      for (let i = tableStart; i <= tableEnd; i++) {
        let value = String.fromCharCode(i);
        // if (ws[`${value}1`].s === undefined) break;
        ws[`${value}1`].s = {
          font: {
            bold: true,
          },
          fill: {
            fgColor: {
              rgb: "FF86c5da",
            },
          },
          alignment: {
            horizontal: "center",
            wrapText: false,
          },
        };

        wscols.push({ wch: 20 });
      }

      ws["!cols"] = wscols;

      let filename = `inv_summ_${da}-${mo}-${ye}_${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

      XLSXStyle.utils.format_cell;
      XLSXStyle.utils.book_append_sheet(wb, ws, filename);

      /* Trigger Download with `writeFile` */
      XLSXStyle.writeFile(wb, filename + ".xlsx", { compression: true });
    },
  },
};
</script>

<style scoped>
.table-panel-grid {
  /* margin: 0 auto;
  display: grid;
  grid-template-columns: 70% auto;
  grid-template-rows: 1fr;
  grid-column-gap: 0px; */
  display: flex;
  height: 100%;
}
</style>
