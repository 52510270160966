<template>
  <v-container class="ma-0 pa-0 fill-height" fluid>
    <v-row class="fill-height">
      <v-col cols="12" class="fill-height pa-0 ma-0">
        <HeatMap2 />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import HeatMap2 from "@/components/Insights/HeatMap2.vue";
import HeatMap2 from "@/components/Insights/HeatMap.vue";
export default {
  components: {
    HeatMap2,
  },
};
</script>

<style></style>
