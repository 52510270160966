<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="10" class="white">
        <v-row>
          <v-col cols="12">
            <Tabs :tabSelected="tabSelected" @setTab="setTab" />
          </v-col>
          <v-col cols="12" class="py-2 elevation-5">
            <v-row class="elevation-5 px-5 py-2" align="center">
              <v-col class="px-12" cols="auto">
                <span
                  class="black--text text-no-wrap text-h5 font-weight-bold text-capitalize"
                  style="letter-spacing: 2px !important"
                  >list of auctions</span
                >
              </v-col>
              <v-col cols="6" class="d-flex">
                <v-form
                  @submit.prevent="searchAuction"
                  class="d-flex"
                  style="width: 100%"
                >
                  <v-text-field
                    class="ma-0 rounded-r-0 pa-0"
                    hide-details
                    type="text"
                    outlined
                    light
                    dense
                    clearable
                    single-line
                    v-model="search"
                    :prepend-inner-icon="'mdi-magnify'"
                    @click:clear="clearSearch"
                  >
                    <template v-slot:label
                      ><span class="pa-0 ma-0"> Search </span>
                    </template>
                  </v-text-field>

                  <v-btn
                    color="primary"
                    class="rounded-l-0 px-12"
                    style="height: 40px"
                    @click="searchAuction"
                  >
                    Search
                  </v-btn>
                </v-form>
              </v-col>

              <v-col class="px-4" cols="auto">
                <v-row justify="end">
                  <v-col cols="auto">
                    <v-btn color="primary px-12" @click="exportAuction"> Export </v-btn>
                  </v-col>
                  <v-col class="px-2" cols="auto">
                    <v-btn light class="primary elevation-5" @click="refresh">
                      <v-icon>mdi-cached</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="auto" class="ml-auto">
                <v-btn color="primary" class="rounded" @click="goToCreateAuction">
                  Create Auction
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="fill-height">
            <TableSummaryAuctions
              :loadingSummaryAuction="loadingSummaryAuction"
              :optionsPagination="optionsPagination"
              :headerTable="HEADER_TABLE_SUMMARY_AUCTION"
              :items="items"
              :tabSelected="tabSelected"
              @updateItemsPerPage="updateItemsPerPage"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Tabs from "@/components/Auctions/Tabs.vue";
import TableSummaryAuctions from "@/components/Auctions/TableSummaryAuctions.vue";
import Form from "@/components/Login/Form.vue";
import { exportTemplate } from "@/utils/ExportExcel.js";
import { fromISOToUtc } from "@/helpers/mocks/dates.js";
import { DateTime } from "luxon";

export default {
  async created() {
    this.now = Math.trunc(new Date().getTime() / 1000);
    this.Timer = window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);

      const finished = this.items.filter((x) => x.Status === "Active");

      if (finished.length === 0) clearInterval(this.Timer);
    }, 1000);
  },

  components: {
    TableSummaryAuctions,
    Form,
    Tabs,
  },
  computed: {
    ...mapState({
      listAuctions: (state) => state.moduleAuctions.listAuctions,
      auctionFormFlag: (state) => state.moduleQtySumm.auctionFormFlag,
      page: (state) => state.moduleAuctions.page,
    }),
    filteredAuctions() {
      return this.items.filter((x) => x.Status === this.tabSelected);
    },
  },
  data() {
    return {
      tabSelected: "A",
      offset: 0,
      search: "",
      optionsPagination: {
        itemsPerPage: 20,
      },
      HEADER_TABLE_SUMMARY_AUCTION: [
        {
          text: "Name",
          value: "name",
        },
        { text: "Status", value: "Status" },
        { text: "Time Left", value: "timeLeft" },

        { text: "Amount of Lots", value: "lots" },
        { text: "Lots With Bids", value: "lotsBidded" },
        // { text: "Created Date", value: "dateCreated" },
        { text: "Start Date", value: "StartDateTime" },
        { text: "Close Date", value: "EndDateTime" },

        { text: "Comments", value: "comments", width: "200px" },

        { text: "", value: "actions" },
      ],
      Timer: null,
      now: null,
      items: [],
      loadingSummaryAuction: false,
    };
  },
  watch: {
    now: {
      handler() {
        if (this.listAuctions.auctions) {
          this.listAuctions.auctions.forEach((x, index) => {
            if (this.items[index]) {
              if (x.Status !== "A") {
                this.items[index]["timeLeft"] = "Finished";
                this.items[index]["auctionFinished"] = true;
              } else {
                const calculatedDate = Math.trunc(
                  Date.parse(fromISOToUtc(x.EndDateTime)) / 1000
                );

                const secondCount = calculatedDate - this.now;
                const days = Math.trunc(secondCount / 60 / 60 / 24);
                const hours = Math.trunc(secondCount / 60 / 60) % 24;
                const minutes = Math.trunc(secondCount / 60) % 60;
                const seconds = secondCount % 60;
                // console.log(secondCount, x);
                if (secondCount <= 0) {
                  if (!this.items[index]["auctionFinished"])
                    this.finishedAuctionUpdate(x);
                  this.items[index]["auctionFinished"] = true;
                  this.items[index]["timeLeft"] = "Finished";
                } else {
                  this.items[index]["timeLeft"] = `${this.twoDigits(
                    days
                  )}:${this.twoDigits(hours)}:${this.twoDigits(minutes)}:${this.twoDigits(
                    seconds
                  )}`;
                }
              }
            }
          });
        }
      },
    },
    listAuctions: {
      deep: true,
      // immediate: true,
      handler() {
        this.mapDataListAuction();
      },
    },
    tabSelected: {
      async handler() {
        await this.getAuctions({
          offset: this.offset,
          status: this.tabSelected,
        });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      setSpinnerShow: "setSpinnerShow",
      setSearchAuction: "moduleAuctions/setSearchAuction",
      setAuctionFormFlag: "moduleQtySumm/setAuctionFormFlag",
    }),
    ...mapActions({
      getAuctions: "moduleAuctions/getAuctions",
    }),
    setTab(value) {
      this.tabSelected = value;
    },
    async finishedAuctionUpdate(item) {
      if (item.status !== "E") {
        this.setSpinnerShow(true);

        setTimeout(async () => {
          await this.getAuctions({
            offset: this.page - 1,
            LIMIT:
              this.optionsPagination.itemsPerPage === "ALL"
                ? this.listAuctions.count
                : this.optionsPagination.itemsPerPage,
            status: this.tabSelected,
          });
          this.setSpinnerShow(false);
        }, 61000);
      }
    },
    searchAuction() {
      this.setSearchAuction(this.search);
      this.getAuctions({
        offset: 0,
        LIMIT:
          this.optionsPagination.itemsPerPage === "ALL"
            ? this.listAuctions.count
            : this.optionsPagination.itemsPerPage,
        status: this.tabSelected,
      });
    },
    clearSearch() {
      this.search = "";

      this.searchAuction();
    },
    exportAuction() {
      exportTemplate(
        this.items,
        this.HEADER_TABLE_SUMMARY_AUCTION,
        `auctions_list`,
        this.HEADER_TABLE_SUMMARY_AUCTION.length
      );
    },
    async goToCreateAuction() {
      this.$router.push({
        path: "/auctions/create-auction",
      });
      // this.$router.push({
      //   path: "/inventory/qtysumm",
      //   query: { auction: true },
      // });
    },
    updateItemsPerPage(value) {
      this.optionsPagination.itemsPerPage = value;
    },
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return "0" + value.toString();
      }
      return value.toString();
    },
    mapDataListAuction() {
      this.items = this.listAuctions.auctions.map((x) => {
        let obj = {};
        obj.Status =
          x.Status === "A" ? "Active" : x.Status === "E" ? "Expired" : "Inactive";

        obj.dateCreated = DateTime.fromISO(
          new Date(x.dateCreated).toISOString().replace("Z", ""),
          {
            zone: "America/Phoenix",
          }
        )
          .toLocal()
          .toFormat("yyyy-LL-dd HH:mm:ss");

        obj.StartDateTime = fromISOToUtc(x.StartDateTime)
          .toLocal()
          .toFormat("yyyy-LL-dd HH:mm:ss");

        obj.EndDateTime = fromISOToUtc(x.EndDateTime)
          .toLocal()
          .toFormat("yyyy-LL-dd HH:mm:ss");

        if (x.Status !== "A")
          return { ...x, ...obj, timeLeft: "Finished", auctionFinished: true };
        else {
          const calculatedDate = Math.trunc(
            Date.parse(fromISOToUtc(x.EndDateTime)) / 1000
          );

          const secondCount = calculatedDate - this.now;
          const days = Math.trunc(secondCount / 60 / 60 / 24);
          const hours = Math.trunc(secondCount / 60 / 60) % 24;
          const minutes = Math.trunc(secondCount / 60) % 60;
          const seconds = secondCount % 60;

          return {
            ...x,
            ...obj,
            auctionFinished: false,
            timeLeft: `${this.twoDigits(days)}:${this.twoDigits(hours)}:${this.twoDigits(
              minutes
            )}:${this.twoDigits(seconds)}`,
          };
        }
      });
    },
    async refresh() {
      await this.getAuctions({ offset: this.offset, status: this.tabSelected });
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  height: 40px !important;
}
input {
  height: 20px;
}
</style>
