<template>
  <TableLayout
    class="om-entry"
    :style="{
      width: $route.meta.width,
    }"
  >
    <template v-slot:table>
      <router-view> </router-view>
    </template>
  </TableLayout>
</template>

<style>
.om-entry .table-layout-container {
  max-height: unset !important;
}
</style>

<style scoped>
.container.fill-height {
  height: auto;
}
</style>

<script>
import TableLayout from "@/layout/TableLayout";
// import Panel from "@/components/OM/Panel";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "ManageOrders",
  components: {
    TableLayout,
    // Panel,
  },
  created() {
    Promise.all([this.getOrdersTerm(), this.getOrdersType()]);
  },
  // computed: {},
  // data() {
  //   return {};
  // },
  methods: {
    //   ...mapMutations({
    //     setNewOrderFlag: "moduleOrders/setNewOrderFlag",
    //     setOrdersData: "moduleOrders/setOrdersData",
    //   }),
    ...mapActions({
      getOrdersTerm: "moduleOrders/getOrdersTerm",
      getOrdersType: "moduleOrders/getOrdersType",
    }),
  },
};
</script>

<style></style>
